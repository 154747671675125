import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EntityView from '../../components/entity/EntityView';
import Header from '../../components/Header';
import { translateIntolerances, translateActivityLevel, translateDietaryPreferences } from '../../utils/translate';
import { Box, CircularProgress } from '@mui/material';

const UserView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fonction pour récupérer les données utilisateur
  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des détails de l\'utilisateur.');
        }

        const data = await response.json();
        setUser(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  // Calcul des pourcentages des macros nutritionnels
  const calculateMacroPercentages = (macros) => {
    const total = (macros.carbohydrates || 0) + (macros.proteins || 0) + (macros.fats || 0);
    return {
      carbohydrates: total ? ((macros.carbohydrates || 0) / total) * 100 : 0,
      proteins: total ? ((macros.proteins || 0) / total) * 100 : 0,
      fats: total ? ((macros.fats || 0) / total) * 100 : 0,
    };
  };

  // Sections à afficher dans EntityView
  const renderSections = () => {
    if (!user) return [];

    const macroPercentages = calculateMacroPercentages(user.targetMacros || {});

    // Transformation du genre pour l'affichage en français
    const genderLabel = user.gender
      ? user.gender === 'male'
        ? 'Masculin'
        : user.gender === 'female'
        ? 'Féminin'
        : 'Autre'
      : 'Non renseigné';

    return [
      {
        title: 'Informations personnelles',
        fields: [
          { label: 'Nom', value: `${user.firstName} ${user.lastName}` },
          { label: 'Email', value: user.email },
          { label: 'Téléphone', value: user.phone || 'Non renseigné' },
          { label: 'Genre', value: genderLabel },
        ],
      },
      {
        title: 'Données physiques',
        fields: [
          { label: 'Âge', value: user.age || 'Non renseigné' },
          { label: 'Poids', value: user.weight ? `${user.weight} kg` : 'Non renseigné' },
          { label: 'Taille', value: user.height ? `${user.height} cm` : 'Non renseigné' },
        ],
      },
      {
        title: 'Objectifs et santé',
        fields: [
          { label: 'Niveau d\'activité', value: user.activityLevel ? translateActivityLevel(user.activityLevel) : 'Non renseigné' },
          { label: 'Objectifs', value: user.goals || 'Non renseigné' },
          { label: 'Conditions médicales', value: user.medicalConditions?.length ? user.medicalConditions.join(', ') : 'Aucune' },
          { label: 'Intolérances', value: user.intolerances?.length ? translateIntolerances(user.intolerances).join(', ') : 'Aucune' },
        ],
      },
      {
        title: 'Régime alimentaire',
        fields: [
          { label: 'Régime', value: user.dietaryPreferences?.length ? translateDietaryPreferences(user.dietaryPreferences).join(', ') : 'Aucun' },
        ],
      },
      {
        title: 'Objectifs nutritionnels',
        fields: [
          { label: 'Glucides', value: `${macroPercentages.carbohydrates.toFixed(2)}%` },
          { label: 'Protéines', value: `${macroPercentages.proteins.toFixed(2)}%` },
          { label: 'Lipides', value: `${macroPercentages.fats.toFixed(2)}%` },
        ],
      },
    ];
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>Erreur : {error}</div>
      </Box>
    );
  }

  return (
    <>
      <Header />
      <Box>
        <EntityView
          title="Détails de l'utilisateur"
          sections={renderSections()}
          navigateBack={() => navigate('/users')}
          onEdit={() => navigate(`/users/edit/${id}`)}
        />
      </Box>
    </>
  );
};

export default UserView;
