// src/themes/theme.js
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: 'Inter, Arial, sans-serif', // Utilisation de la police Inter
    h1: {
      fontFamily:'Futuru',
      fontWeight: 600,
      fontSize: '3rem',
      letterSpacing:'0.02em',
    },
    h2: {
      fontFamily:'Futuru',
      fontWeight: 600,
      fontSize: '2.5rem',
      letterSpacing:'0.02em',
    },
    h3: {
      fontFamily:'Futuru',
      fontWeight: 600,
      fontSize: '2rem',
      letterSpacing:'0.02em',
    },
    h4: {
      fontFamily:'Futuru',
      fontWeight: 600,
      fontSize: '1.5rem',
      textTransform: 'none',
      letterSpacing:'0.02em',
    },
    h5: {
      fontFamily:'Futuru',
      fontWeight: 600,
      fontSize: '1.4rem',
      textTransform: 'none',
      letterSpacing:'0.02em',
    },
    h6: {
      fontFamily:'Futuru',
      fontWeight: 600,
      fontSize: '1.2rem',
      textTransform: 'none',
      letterSpacing:'0.02em',
    },
    body1: {
      fontSize: '1rem',
    },
  },
  palette: {
    primary: {
      main: '#5FB1F9',
    },
    secondary: {
      main: '#F2EFEA',
    },
    background: {
      default: '#f5f5f5',
      paper: '#ffffff',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'uppercase',
          fontWeight:'600',
          boxShadow: 'none !important',
        },
      },
    },
  },
});

export default theme;
