import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Typography,
  IconButton,
  InputAdornment,
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CoachHeader from '../../components/coach/CoachHeader';
import { AuthContext } from '../../context/AuthContext';



const EditProfile = () => {
  const { user } = useContext(AuthContext); // Récupération de l'utilisateur connecté
  const [profile, setProfile] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    password: '',
    confirmPassword: '',
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false); // État pour vérifier si l'utilisateur est abonné
  const [subscriptionStartDate, setSubscriptionStartDate] = useState(null); // Date de début de l'abonnement
  // eslint-disable-next-line 
  const [role, setRole] = useState(''); // État pour stocker le rôle de l'utilisateur
  // eslint-disable-next-line
  const [subscriptionStatus, setSubscriptionStatus] = useState('inactive'); // Statut de l'abonnement (actif ou non)

  // Fonction pour rafraîchir le token JWT
  const refreshAuthToken = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }

      const data = await response.json();
      localStorage.setItem('authToken', data.token); // Mettre à jour le token dans le localStorage
    } catch (error) {
      console.error('Erreur lors du rafraîchissement du token:', error);
    }
  };

  // Fonction pour récupérer les informations du profil
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        // Rafraîchir le token avant de récupérer les informations du profil
        await refreshAuthToken();

        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/profile`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch profile');
        }

        const data = await response.json();
        setProfile({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone,
          password: '',
          confirmPassword: '',
        });
        setIsSubscribed(data.isSubscribed); // Mettre à jour l'état de l'abonnement
        setSubscriptionStartDate(data.subscriptionStartDate); // Mettre à jour la date de début de l'abonnement
        setSubscriptionStatus(data.isSubscribed ? 'active' : 'inactive'); // Mettre à jour le statut de l'abonnement
        setRole(data.role); // Mettre à jour le rôle de l'utilisateur
      } catch (err) {
        setError(err.message);
      }
    };

    fetchProfile();
  }, []);

  // Fonction pour gérer les changements dans les champs du formulaire
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // Fonction pour valider le mot de passe
  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  // Fonction pour soumettre le formulaire de mise à jour du profil
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccessMessage('');

    if (profile.password && !validatePassword(profile.password)) {
      setError(
        'Le mot de passe doit contenir au moins 8 caractères, une majuscule, un chiffre et un caractère spécial.'
      );
      setLoading(false);
      return;
    }

    if (profile.password !== profile.confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      setLoading(false);
      return;
    }

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(profile),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      setSuccessMessage('Votre profil a été mis à jour avec succès !');
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  // Fonction pour gérer la redirection vers le portail client Stripe
  const handleManageSubscription = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/customer-portal`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la création de la session du Customer Portal');
      }

      const { url } = await response.json();
      window.location.href = url; // Rediriger vers le Customer Portal
    } catch (error) {
      console.error('Erreur lors de la gestion de l\'abonnement:', error);
      setError('Erreur lors de la gestion de l\'abonnement. Veuillez réessayer.');
    }
  };

  // Fonction pour gérer la création d'une session de paiement Stripe
  const handleSubscribe = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/checkout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({ subscriptionProduct: process.env.REACT_APP_STRIPE_PRICE_ID }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la création de la session Stripe');
      }

      const { url } = await response.json();
      window.location.href = url; // Rediriger vers la page de paiement Stripe
    } catch (error) {
      console.error('Erreur lors de la création de la session Stripe:', error);
      setError('Erreur lors de la création de la session Stripe. Veuillez réessayer.');
    }
  };

  // Afficher un indicateur de chargement pendant le chargement des données
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }


return (
  <>
    {/* Afficher CoachHeader uniquement pour les coachs */}
    {user.role === 'coach' && user.isSubscribed && <CoachHeader />}
    <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingTop: '5vh',
          paddingBottom: '5vh',
        }}
      >
        <Box
          sx={{
            maxWidth: '400px',
            width: '100%',
            padding: '30px',
            border: '1px solid #ddd',
            borderRadius: '10px',
            textAlign: 'center',
            backgroundColor: 'white',
          }}
        >
          <Typography variant="h3" sx={{ marginBottom: 3 }}>
            Mon profil
          </Typography>

          {/* Section "Mon abonnement" - Affichée uniquement pour les coachs */}
          {user.role === 'coach' && (
            <>
              <Typography variant="h5" sx={{ marginBottom: 1, textAlign: 'left' }}>
                Mon abonnement
              </Typography>

              {isSubscribed ? (
                <>
                  <Typography variant="body1" sx={{ textAlign: 'left', mb: 1 }}>
                    Statut : <strong>actif</strong>
                  </Typography>
                  {subscriptionStartDate && (
                    <Typography variant="body1" sx={{ textAlign: 'left', mb: 1 }}>
                      Date de début: <strong>{new Date(subscriptionStartDate).toLocaleDateString()}</strong>
                    </Typography>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginBottom: 3 }}
                    onClick={handleManageSubscription}
                    fullWidth
                  >
                    Gérer mon abonnement
                  </Button>
                </>
              ) : (
                <>
                  <Typography variant="body1" sx={{ textAlign: 'left', mb: 2 }}>
                    Statut : <strong>inactif</strong>
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginBottom: 3 }}
                    onClick={handleSubscribe}
                    fullWidth
                  >
                    S'abonner
                  </Button>
                </>
              )}
            </>
          )}

          {error && (
            <Typography variant="body1" color="error" sx={{ mb: 2 }}>
              {error}
            </Typography>
          )}
          {successMessage && (
            <Typography variant="body1" color="success" sx={{ mb: 2 }}>
              {successMessage}
            </Typography>
          )}

          {/* Section "Modifier mes informations" */}
          <Typography variant="h5" sx={{ marginBottom: 3, textAlign: 'left' }}>
            Modifier mes informations
          </Typography>

          <form onSubmit={handleSubmit}>
            <TextField
              label="Prénom"
              name="firstName"
              value={profile.firstName}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Nom"
              name="lastName"
              value={profile.lastName}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Email"
              name="email"
              value={profile.email}
              disabled
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Téléphone"
              name="phone"
              value={profile.phone}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Nouveau mot de passe"
              name="password"
              type={showPassword ? 'text' : 'password'}
              value={profile.password}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label="Confirmer le mot de passe"
              name="confirmPassword"
              type={showPassword ? 'text' : 'password'}
              value={profile.confirmPassword}
              onChange={handleInputChange}
              fullWidth
              sx={{ mb: 2 }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Button variant="contained" color="secondary" type="submit" fullWidth sx={{ mb: 2 }}>
              Mettre à jour
            </Button>
          </form>
        </Box>
      </Box>
    </>
  );
};

export default EditProfile;