import React, { useState } from 'react';
import { Box, Typography, Card, CardContent, Chip, Drawer, Divider } from '@mui/material';
import RecipeDetails from './RecipeDetails';

// Traduction des jours de la semaine
const daysInFrench = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];

const mealTranslations = {
  breakfast: 'Petit-déjeuner',
  morningSnack: 'Encas matin', 
  lunch: 'Déjeuner',
  snack: 'Encas journée',
  dinner: 'Dîner',
};

const DayPlans = ({ dayPlans, targetMacros }) => {
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const handleRecipeClick = (recipe) => {
    console.log('Recette sélectionnée :', recipe);
    setSelectedRecipe(recipe);
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedRecipe(null);
  };

  // Fonction pour calculer les calories totales du jour à partir du localStorage
  const calculateTotalCalories = (dayPlan) => {
    // Récupérer les recettes ajustées stockées dans le localStorage
    const adjustedRecipes = JSON.parse(localStorage.getItem('adjustedRecipes')) || [];

    // Calculer les calories totales en utilisant les données ajustées du localStorage
    return ['breakfast', 'morningSnack', 'lunch', 'snack', 'dinner'].reduce((total, mealKey) => {
      const meal = dayPlan.meals[mealKey];
      if (meal) {
        // Trouver la recette ajustée correspondante dans le localStorage
        const adjustedRecipe = adjustedRecipes.find((recipe) => recipe.name === meal.name);
        if (adjustedRecipe && adjustedRecipe.macros && adjustedRecipe.macros.calories) {
          total += adjustedRecipe.macros.calories;
        }
      }
      return total;
    }, 0);
  };

  return (
    <Box sx={{ px: 0 }}>
      <Typography variant="h5" sx={{ mb: 2, mt:3 }}>
        Plans des repas
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(320px, 1fr))',
          gap: 3,
        }}
      >
        {dayPlans.map((dayPlan, index) => {
          const totalCalories = calculateTotalCalories(dayPlan);

          return (
            <Card
              key={index}
              sx={{
                border: '1px solid #DDD',
                borderRadius: 2,
                boxShadow: 'none',
                transition: '0.3s',
                '&:hover': {
                  boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                },
              }}
            >
              <CardContent>
                {/* Affichage du jour */}
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    mb: 2,
                  }}
                >
                  <Typography variant="h6">{daysInFrench[index]}</Typography>
                  <Box>
                    <Chip
                      label={dayPlan.dayType === 'sport' ? 'Sport' : 'Repos'}
                      color={dayPlan.dayType === 'sport' ? 'primary' : 'default'}
                      size="small"
                      sx={{ mr:1 }}
                    />
                    {/* Affichage des calories totales */}
                    <Chip
                      label={`${Math.round(totalCalories)} calories`}
                      color="secondary"
                      size="small"
                      sx={{ backgroundColor:"white", border:"1px solid #DDD" }}
                    />
                  </Box>
                </Box>

                {/* Affichage des repas */}
                <Divider sx={{ mb: 2 }} />
                {['breakfast', 'morningSnack', 'lunch', 'snack', 'dinner'].map((mealKey) => (
                  <Box
                    key={mealKey}
                    sx={{
                      mb: 1.5,
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      variant="body2"
                      sx={{
                        color: dayPlan.meals[mealKey] ? 'text.primary' : 'text.secondary',
                        cursor: dayPlan.meals[mealKey] ? 'pointer' : 'default',
                      }}
                      onClick={() => dayPlan.meals[mealKey] && handleRecipeClick(dayPlan.meals[mealKey])}
                    >
                      {mealTranslations[mealKey]} 
                    </Typography>
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: 1,
                      }}
                    >
                      {dayPlan.meals[mealKey] ? (
                        <>
                          <Box onClick={() => dayPlan.meals[mealKey] && handleRecipeClick(dayPlan.meals[mealKey])}>
                            <Typography variant="body2" sx={{ color: 'text.primary', cursor:'pointer', fontWeight:'bold' }}>
                              {dayPlan.meals[mealKey].name} ↗
                            </Typography>
                          </Box>
                        </>
                      ) : (
                        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                          Aucune recette
                        </Typography>
                      )}
                    </Box>
                  </Box>
                ))}
              </CardContent>
            </Card>
          );
        })}
      </Box>

      {/* Drawer pour afficher les détails de la recette */}
      <Drawer anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
        <RecipeDetails
          recipe={selectedRecipe}
          targetMacros={targetMacros} // Transmet les macros cibles
          open={drawerOpen}
          onClose={handleCloseDrawer}
        />
      </Drawer>
    </Box>
  );
};

export default DayPlans;
