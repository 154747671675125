import React from 'react';
import { Box, Typography } from '@mui/material';

const EnergyNeeds = ({ user }) => {
  // Affiche l'objet "user" complet pour vérifier les informations reçues
  console.log("Données utilisateur dans EnergyNeeds:", user);

  if (!user?.weight || !user?.height || !user?.age || !user?.activityLevel || !user?.gender) {
    console.log("Informations insuffisantes pour calculer les besoins caloriques:", {
      weight: user?.weight,
      height: user?.height,
      age: user?.age,
      activityLevel: user?.activityLevel,
      gender: user?.gender
    });
    return (
      <Typography variant="body2">
        Informations insuffisantes pour calculer les besoins caloriques.
      </Typography>
    );
  }

  const weight = user.weight; // en kg
  const height = user.height; // en cm
  const age = user.age;       // en années
  const activityLevel = user.activityLevel; // "sedentary", "moderate" ou "active"
  const gender = user.gender; // "male", "female" ou "other"

  const activityMultipliers = {
    sedentary: 1.2,
    lightlyActive: 1.375,
    moderatelyActive: 1.55,
    veryActive: 1.725,
    athlete: 1.9,
  };
  
  // Calcul du métabolisme de base (MB)
  let BMR;
  if (gender === 'male') {
    BMR = 10 * weight + 6.25 * height - 5 * age + 5;
  } else if (gender === 'female') {
    BMR = 10 * weight + 6.25 * height - 5 * age - 161;
  } else {
    BMR = 10 * weight + 6.25 * height - 5 * age;
  }
  
  // Utilisation du bon coefficient d'activité
  const dailyCalories = BMR * (activityMultipliers[activityLevel] || 1.2);
  
  // Ajout d'une dépense calorique réaliste pour le sport
  const sportExtraCalories = 400; // Ex : 400 kcal par séance de sport
  const sportCalories = dailyCalories + sportExtraCalories;

  return (
    <Box
      sx={{
        mt: 2,
        py: { xs: 1, sm: 2 },
        px: { xs: 2, sm: 2 },
        backgroundColor: "#EEE",
        borderRadius: 2,
        display: "flex",
        alignItems: 'center',
        flexDirection: "row",
        justifyContent: 'space-between'
      }}
    >
      <Box>
        <Typography variant="body2" fontWeight="bold" sx={{ color: 'text.primary' }}>
          Besoins caloriques
        </Typography>
      </Box>

      <Box sx={{ display: "flex", gap: { xs: 0.5, sm: 1 } }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: 'primary.main',
            px: 3,
            py: 0.5,
            borderRadius: 10
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: { xs: '0.6em', sm: '0.8em' }, fontWeight: 'bold' }}
          >
            Sport
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: { xs: '0.6em', sm: '0.8em' }, fontWeight: 'bold' }}
          >
            {Math.round(sportCalories)} cal
          </Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            alignItems: "center",
            flexDirection: "column",
            backgroundColor: '#DDD',
            px: 3,
            py: 0.5,
            borderRadius: 10
          }}
        >
          <Typography
            variant="body2"
            sx={{ fontSize: { xs: '0.6em', sm: '0.8em' }, fontWeight: 'bold' }}
          >
            Repos
          </Typography>
          <Typography
            variant="body2"
            sx={{ fontSize: { xs: '0.6em', sm: '0.8em' }, fontWeight: 'bold' }}
          >
            {Math.round(dailyCalories)} cal
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default EnergyNeeds;
