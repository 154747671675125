export const activityLevelOptions = [
    { value: 'sedentary', label: 'Sédentaire' },
    { value: 'moderate', label: 'Modéré' },
    { value: 'active', label: 'Actif' },
  ];
  
  export const intoleranceOptions = [
    { value: 'gluten', label: 'Gluten' },
    { value: 'lactose', label: 'Lactose' },
    { value: 'nuts', label: 'Noix' },
    { value: 'shellfish', label: 'Fruits de mer' },
    { value: 'soy', label: 'Soja' },
    { value: 'eggs', label: 'Œufs' },
    { value: 'fish', label: 'Poisson' },
    { value: 'pork', label: 'Porc' },
    { value: 'beef', label: 'Bœuf' },
    { value: 'chicken', label: 'Poulet' },
    { value: 'turkey', label: 'Dinde' },
    { value: 'corn', label: 'Maïs' },
    { value: 'sesame', label: 'Sésame' },
    { value: 'mustard', label: 'Moutarde' },
    { value: 'peanuts', label: 'Arachides' },
    { value: 'citrus', label: 'Agrumes' },
    { value: 'mushrooms', label: 'Champignons' },
    { value: 'legumes', label: 'Légumineuses' },
    { value: 'other', label: 'Autre' },
  ];
  
  export const dietaryPreferenceOptions = [
    { value: 'vegetarian', label: 'Végétarien' },
    { value: 'vegan', label: 'Vegan' },
    { value: 'gluten-free', label: 'Sans gluten' },
    { value: 'dairy-free', label: 'Sans produits laitiers' },
    { value: 'paleo', label: 'Paléo' },
    { value: 'keto', label: 'Kéto' },
    { value: 'other', label: 'Autre' },
  ];
  

  export const roleOptions = [
    { value: 'user', label: 'Utilisateur' },
    { value: 'coach', label: 'Coach' },
    { value: 'admin', label: 'Administrateur' },
  ];
  