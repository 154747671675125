// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async'; 
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import theme from './themes/theme'; 
import AuthProvider from './context/AuthContext';
import { PublicRoute, UserRoute, AdminRoute, CoachRoute, SubscribedCoachRoute } from './routes/ProtectedRoutes';

import Login from './views/account/Login';
import RecipeForm from './views/recipes/CreateRecipe';
import RecipeList from './views/recipes/RecipeList';
import EditRecipe from './views/recipes/EditRecipe';
import UserList from './views/users/UserList';
import UserView from './views/users/UserView';
import EditUser from './views/users/EditUser';
import AddUser from './views/users/AddUser';
import Dashboard from './views/Dashboard';
import CreateWeekPlan from './views/plans/CreateWeekPlan';
import PlanList from './views/plans/PlanList';
import PlanView from './views/plans/PlanView';
import EditPlan from './views/plans/EditPlan';
import UserPlanView from './views/plans/UserPlanView';
import TopBar from './components/TopBar';
import Footer from './components/Footer';
import ForgotPassword from './views/account/ForgotPassword';
import ResetPassword from './views/account/ResetPassword';
import PrivacyPolicy from './views/PrivacyPolicy';
import HomePage from './views/static/Homepage';
import About from './views/static/About';
import Offers from './views/static/Offers';
import Contact from './views/static/Contact';

import EditProfil from './views/account/EditProfil';

import CoachDashboard from './views/coach/CoachDashboard'
import CheckoutPage from './views/coach/checkout/CheckoutPage'
import CheckoutCancelPage from './views/coach/checkout/CheckoutCancelPage'
import CheckoutSuccessPage from './views/coach/checkout/CheckoutSuccessPage'

import AddClient from './views/coach/clients/AddClient'
import ClientList from './views/coach/clients/ClientList'
import ClientView from './views/coach/clients/ClientView'
import EditClient from './views/coach/clients/EditClient'

import CoachPlanList from './views/coach/plans/CoachPlanList'
import CoachCreateWeekPlan from './views/coach/plans/CoachCreateWeekPlan.js'
import CoachPlanView from './views/coach/plans/CoachPlanView.js'
import CoachEditPlan from './views/coach/plans/CoachEditPlan.js'

function App() {
  return (
    <ThemeProvider theme={theme}>
    <HelmetProvider>
      <Router>
        <AuthProvider>
        <Box
          className="App"
          sx={{
            minHeight: '100vh',
            display: 'flex',
            flexDirection: 'column',
          }}
        >            
            <TopBar />

            {/* Page Routes */}
            <Routes>

              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
              <Route path="/contact" element={<Contact />} />

              <Route element={<PublicRoute />}>
                <Route path="/login" element={<Login />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />

                <Route path="/home" element={<HomePage />} />
                <Route path="/about" element={<About />} />
                <Route path="/offers" element={<Offers />} />
              </Route>

              <Route element={<UserRoute />}>
                <Route path="/week" element={<UserPlanView />} />
                <Route path="/profile" element={<EditProfil />} />
              </Route>

              <Route element={<AdminRoute />}>
                <Route path="/" element={<Dashboard />} />

                <Route path="/recipes" element={<RecipeList />} />
                <Route path="/recipes/create" element={<RecipeForm />} />
                <Route path="/recipes/edit/:id" element={<EditRecipe />} />

                <Route path="/users" element={<UserList />} />
                <Route path="/users/create" element={<AddUser />} />
                <Route path="/users/view/:id" element={<UserView />} />
                <Route path="/users/edit/:id" element={<EditUser />} />

                <Route path="/plans" element={<PlanList />} />
                <Route path="/plans/create" element={<CreateWeekPlan />} />
                <Route path="/plans/view/:id" element={<PlanView />} />
                <Route path="/plans/edit/:id" element={<EditPlan />} />

              </Route>

              <Route element={<CoachRoute />}>
                <Route path="/coach" element={<CoachDashboard />} />
                <Route path="/coach/profile" element={<EditProfil />} />
                <Route path="/coach/checkout" element={<CheckoutPage />} />
                <Route path="/coach/checkout/cancel" element={<CheckoutCancelPage />} />
                <Route path="/coach/checkout/success" element={<CheckoutSuccessPage />} />
              </Route>

              <Route element={<SubscribedCoachRoute />}>
                <Route path="/coach/clients" element={<ClientList />} />
                <Route path="/coach/clients/create" element={<AddClient />} />
                <Route path="/coach/clients/view/:id" element={<ClientView />} />
                <Route path="/coach/clients/edit/:id" element={<EditClient />} />

                <Route path="/coach/plans" element={<CoachPlanList />} />
                <Route path="/coach/plans/create" element={<CoachCreateWeekPlan />} />
                <Route path="/coach/plans/view/:clientId/:weekPlanId" element={<CoachPlanView />} />
                <Route path="/coach/plans/edit/:clientId/:weekPlanId" element={<CoachEditPlan />} />

                <Route path="/coach/recipes" element={<RecipeList />} />
              </Route>

            </Routes>
            <Footer /> 
          </Box>
        </AuthProvider>
      </Router>
    </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;