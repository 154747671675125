import React, { useContext } from 'react';
import { Box, Typography, Card, CardContent, Stack, Chip, IconButton } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { AuthContext } from '../../context/AuthContext'; 

const EntityList = ({ entities, entityName, onView, onEdit, onDelete }) => {
  const { user } = useContext(AuthContext); // Récupérer les données utilisateur

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      {entities.length > 0 ? (
        entities.map((entity) => (
          <Card
            key={entity._id}
            sx={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              border: '1px solid #DDD',
              boxShadow: 'none',
              '&:hover': {
                boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
              },
            }}
          >
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
                textAlign: 'left',
                width: '100%',
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <Typography variant="h5">{entity.firstName} {entity.lastName}</Typography>
                <Stack direction="row" spacing={1}>
                  <Chip
                    label={entity.active ? 'Actif' : 'Inactif'}
                    color={entity.active ? 'primary' : 'default'}
                  />
                  {/* Afficher le rôle uniquement si l'utilisateur actif n'est pas coach */}
                  {user?.role !== 'coach' && (
                    <Chip
                      label={entity.role}
                      color={
                        entity.role === 'admin' ? 'secondary' :
                        entity.role === 'coach' ? 'primary' : 'default'
                      }
                    />
                  )}
                </Stack>
              </Box>
              <Box>
                <IconButton onClick={() => onView(entity)}>
                  <VisibilityIcon />
                </IconButton>
                <IconButton onClick={() => onEdit(entity)}>
                  <EditIcon />
                </IconButton>
                <IconButton onClick={() => onDelete(entity)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </CardContent>
          </Card>
        ))
      ) : (
        <Typography variant="h6" sx={{ textAlign: 'center' }}>
          Aucun {entityName} trouvé.
        </Typography>
      )}
    </Box>
  );
};

export default EntityList;
