import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, CircularProgress, Stack, Button, IconButton, Paper } from '@mui/material';
import CoachHeader from '../../components/coach/CoachHeader';
import { useNavigate } from 'react-router-dom';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AddCircleIcon from '@mui/icons-material/AddCircle';

const CoachDashboard = () => {
  const navigate = useNavigate();
  const [clients, setClients] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [weekPlans, setWeekPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
        try {
          // Fetch clients
          const clientResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/clients`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          });
          if (!clientResponse.ok) throw new Error('Erreur lors de la récupération des clients');
          const clientData = await clientResponse.json();
      
          // Fetch recipes
          const recipeResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/recipes`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          });
          if (!recipeResponse.ok) throw new Error('Erreur lors de la récupération des recettes');
          const recipeData = await recipeResponse.json();
      
          // Fetch week plans
          const weekPlanResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/weekplans`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          });
          if (!weekPlanResponse.ok) throw new Error('Erreur lors de la récupération des programmes hebdomadaires');
          const weekPlanData = await weekPlanResponse.json();
      
          // Set state
          setClients(clientData);
          setRecipes(recipeData);
          setWeekPlans(weekPlanData);
        } catch (err) {
          console.error(err.message);
          setError(err.message);
        } finally {
          setLoading(false);
        }
      };
      

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">{error}</Typography>
      </Box>
    );
  }

  const clientStats = {
    totalClients: clients.length,
    activeClients: clients.filter(client => client.active).length,
  };

  const recipeStats = {
    totalRecipes: recipes.length,
  };

  const weekPlanStats = {
    totalWeekPlans: weekPlans.length,
  };

  return (
    <>
      <CoachHeader />
      <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>
        <Typography variant="h2" sx={{ mb: 2 }}>Bienvenue Coach</Typography>
        <Stack direction="row" sx={{ mb: 5 }} spacing={1}>
          <Button variant="contained" color="primary" onClick={() => navigate('/clients/create')} startIcon={<AddCircleIcon />}>
            Ajouter un client
          </Button>
          <Button variant="contained" color="primary" onClick={() => navigate('/recipes/create')} startIcon={<AddCircleIcon />}>
            Ajouter une recette
          </Button>
          <Button variant="contained" color="primary" onClick={() => navigate('/plans/create')} startIcon={<AddCircleIcon />}>
            Ajouter un programme
          </Button>
        </Stack>
        <Grid container spacing={3}>
          {/* KPI Cards */}
          <Grid item xs={12} md={4}>
            <Card elevation="0" sx={{ border: '1px solid #DDD', px: 1, pt: 1, pb: 0 }}>
              <CardContent>
                <Typography variant="h1">{clientStats.totalClients}</Typography>
                <Typography variant="h6">Clients</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card elevation="0" sx={{ border: '1px solid #DDD', px: 1, pt: 1, pb: 0 }}>
              <CardContent>
                <Typography variant="h1">{recipeStats.totalRecipes}</Typography>
                <Typography variant="h6">Recettes</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={4}>
            <Card elevation="0" sx={{ border: '1px solid #DDD', px: 1, pt: 1, pb: 0 }}>
              <CardContent>
                <Typography variant="h1">{weekPlanStats.totalWeekPlans}</Typography>
                <Typography variant="h6">Programmes</Typography>
              </CardContent>
            </Card>
          </Grid>

          {/* Active Clients List */}
          <Grid item xs={12}>
            <Paper elevation={0} sx={{ border: '1px solid #DDD', px: 3, py: 3 }}>
              <Typography variant="h5" sx={{ mb: 1.5 }} gutterBottom>Clients actifs</Typography>
              <Stack spacing={2}>
                {clients.filter(client => client.active).map((client) => (
                  <Box key={client._id} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', px: 2, py: 1, borderRadius: 1, backgroundColor: '#f9f9f9' }}>
                    <Typography variant="body1">{client.firstName} {client.lastName}</Typography>
                    <IconButton onClick={() => navigate(`/clients/view/${client._id}`)}>
                      <KeyboardArrowRightIcon color="black" />
                    </IconButton>
                  </Box>
                ))}
              </Stack>
            </Paper>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default CoachDashboard;
