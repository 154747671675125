import React, { useState, useContext, useEffect } from 'react';
import { Box, Button, Typography, CircularProgress } from '@mui/material';
import { AuthContext } from '../../../context/AuthContext'; // Importer le contexte Auth
import { useNavigate } from 'react-router-dom'; // Importer useNavigate pour la redirection

const Checkout = () => {
  const { user, loading, refreshAuthToken } = useContext(AuthContext); // Récupérer refreshAuthToken du contexte
  const [checkoutLoading, setCheckoutLoading] = useState(false);
  const [isRefreshingToken, setIsRefreshingToken] = useState(true); // État pour le rafraîchissement du token
  const [error, setError] = useState(null);
  const navigate = useNavigate(); // Initialiser useNavigate

  // Rafraîchir le token au chargement de la page
  useEffect(() => {
    if (!loading) {
      refreshAuthToken()
        .then(() => {
          setIsRefreshingToken(false); // Le token est rafraîchi, on peut continuer
        })
        .catch((error) => {
          console.error('Erreur lors du rafraîchissement du token:', error);
          setIsRefreshingToken(false); // Même en cas d'erreur, on arrête le chargement
        });
    }
  }, [loading, refreshAuthToken]);

  // Rediriger vers /coach/profile si l'utilisateur est déjà abonné
  useEffect(() => {
    if (user && user.isSubscribed && !isRefreshingToken) {
      navigate('/coach/profile'); // Rediriger vers /coach/profile
    }
  }, [user, isRefreshingToken, navigate]);

  // Afficher les informations de l'utilisateur dans la console pour déboguer
  useEffect(() => {
    if (user) {

    }
  }, [user]);

  const handleCheckout = async () => {
    setCheckoutLoading(true);
    setError(null);

    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/checkout`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ subscriptionProduct: process.env.REACT_APP_STRIPE_PRICE_ID })
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la création de la session Stripe');
      }

      const { url } = await response.json();
      window.location.href = url; // Redirige l'utilisateur vers le portail Stripe
    } catch (error) {
      setError(error.message);
      setCheckoutLoading(false);
    }
  };


  // Afficher un indicateur de chargement pendant le rafraîchissement du token
  if (isRefreshingToken || loading) {
    return (
      <Box sx={{ padding: '20px', textAlign: 'center', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ padding: '20px', textAlign: 'center', height: '78vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box>
        <Typography variant="h3">
          {user ? `${user.firstName}, ${user.isSubscribed ? 'vous avez un abonnement' : 'vous n\'avez pas d\'abonnement'}` : 'Chargement...'}
        </Typography>

        {!user ? (
          <CircularProgress />
        ) : (
          <>
            {!user.isSubscribed && ( // Afficher le bouton uniquement si l'utilisateur n'est pas abonné
              <Button
                variant="contained"
                color="primary"
                onClick={handleCheckout}
                disabled={checkoutLoading}
                sx={{ marginTop: '20px' }}
              >
                {checkoutLoading ? <CircularProgress size={24} /> : 'Commencer l\'abonnement'}
              </Button>
            )}

            {error && <Typography color="error">{error}</Typography>}
          </>
        )}
      </Box>
    </Box>
  );
};

export default Checkout;