import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogActions, Button, Typography, CircularProgress, TextField } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import RecipeSelection from '../../../components/plans/RecipeSelection';
import Recommendations from '../../../components/plans/Recommendations';
import CoachHeader from '../../../components/coach/CoachHeader';

const CoachEditPlan = () => {
  const { clientId, weekPlanId } = useParams(); // Récupère les IDs client et plan depuis l'URL
  const [recipes, setRecipes] = useState([]);
  const [weekNumber, setWeekNumber] = useState('');
  const [dayPlans, setDayPlans] = useState([]);
  const [recommendations, setRecommendations] = useState({
    sleep: '',
    alcohol: '',
    dairyProducts: '',
    hydration: '',
    physicalActivity: '',
    stressManagement: '',
    nutrition: '',
    others: '',
  });
  const [summary, setSummary] = useState('');
  const [step, setStep] = useState(2); // Commence à l'étape 2 directement
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const [clientDetails, setClientDetails] = useState(null);
  const [loading, setLoading] = useState(true); // Nouvel état de chargement
  const navigate = useNavigate();

  useEffect(() => {
    const fetchWeekPlanAndRecipes = async () => {
      try {
        // Récupérer le plan de la semaine pour le coach
        const weekPlanResponse = await fetch(
          `${process.env.REACT_APP_BACKEND_URL}/api/weekplans/clients/${clientId}/${weekPlanId}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          }
        );
        if (!weekPlanResponse.ok) {
          throw new Error('Failed to fetch week plan');
        }
        const weekPlanData = await weekPlanResponse.json();

        // Récupérer les recettes
        const recipesResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/recipes`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        if (!recipesResponse.ok) {
          throw new Error('Failed to fetch recipes');
        }
        const recipesData = await recipesResponse.json();

        // Enrichir les dayPlans avec les noms des recettes
        const enrichedDayPlans = weekPlanData.dayPlans.map((dayPlan) => {
          const enrichedMeals = {};
          for (const [mealKey, mealValue] of Object.entries(dayPlan.meals)) {
            if (mealValue) {
              const foundRecipe = recipesData.find((recipe) => recipe._id === mealValue._id);
              enrichedMeals[mealKey] = foundRecipe ? { ...mealValue, name: foundRecipe.name } : mealValue;
            } else {
              enrichedMeals[mealKey] = mealValue;
            }
          }
          return { ...dayPlan, meals: enrichedMeals };
        });

        // Mettre à jour l'état avec les données récupérées
        setClientDetails(weekPlanData.user);
        setWeekNumber(weekPlanData.weekNumber);
        setDayPlans(enrichedDayPlans);
        setRecommendations(weekPlanData.recommendations);
        setSummary(weekPlanData.summary);
        setRecipes(recipesData);

        setLoading(false); // Fin du chargement
      } catch (error) {
        console.error('Error fetching week plan or recipes:', error);
      }
    };

    fetchWeekPlanAndRecipes();
  }, [clientId, weekPlanId]);

  const handleDayPlanChange = (dayIndex, meal, recipeId) => {
    const updatedDayPlans = [...dayPlans];
    if (!updatedDayPlans[dayIndex]) {
      updatedDayPlans[dayIndex] = { day: '', meals: {} };
    }
    updatedDayPlans[dayIndex].meals[meal] = recipeId;
    setDayPlans(updatedDayPlans);
  };

  const handleUpdateWeekPlan = async () => {
    try {
      const validDayPlans = dayPlans.map((dayPlan) => ({
        ...dayPlan,
        meals: {
          breakfast: dayPlan.meals.breakfast || null,
          morningSnack: dayPlan.meals.morningSnack || null,
          lunch: dayPlan.meals.lunch || null,
          snack: dayPlan.meals.snack || null,
          dinner: dayPlan.meals.dinner || null,
        },
      }));
      const updatedWeekPlan = {
        weekNumber,
        dayPlans: validDayPlans,
        recommendations,
        summary,
      };
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/weekplans/clients/${clientId}/${weekPlanId}`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
          body: JSON.stringify(updatedWeekPlan),
        }
      );

      if (!response.ok) {
        throw new Error('Failed to update week plan');
      }

      setSuccessModalOpen(true);
    } catch (error) {
      console.error('Error updating week plan:', error);
    }
  };

  const nextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    navigate("/coach/plans/");
  };

  return (
    <>
      <CoachHeader />

      <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography variant="h3" sx={{ marginBottom: 2 }}>
              Édition du programme
            </Typography>

            {step === 2 && clientDetails && dayPlans.length > 0 && recipes.length > 0 && (
              <>
                <Box sx={{ mt: 4, mb: 2 }}>
                  <TextField
                    label="Bilan"
                    fullWidth
                    multiline
                    rows={4}
                    value={summary}
                    onChange={(e) => setSummary(e.target.value)}
                  />
                </Box>
                <RecipeSelection
                  weekNumber={weekNumber}
                  setWeekNumber={setWeekNumber}
                  dayPlans={dayPlans}
                  setDayPlans={setDayPlans}
                  recipes={recipes}
                  handleDayPlanChange={handleDayPlanChange}
                  selectedClient={clientDetails}
                  userPreferences={clientDetails?.dietaryPreferences ?? []}
                  nextStep={nextStep}
                  prevStep={prevStep}
                />
              </>
            )}

            {step === 3 && (
              <Recommendations
                recommendations={recommendations}
                setRecommendations={setRecommendations}
                handleCreateWeekPlan={handleUpdateWeekPlan}
                selectedClient={clientDetails}
                userPreferences={clientDetails?.dietaryPreferences ?? []}
                prevStep={prevStep}
              />
            )}

            <Dialog open={successModalOpen} onClose={handleCloseSuccessModal}>
              <DialogTitle>Plan mis à jour avec succès</DialogTitle>
              <DialogActions>
                <Button onClick={handleCloseSuccessModal} color="primary">
                  OK
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )}
      </Box>
    </>
  );
};

export default CoachEditPlan;
