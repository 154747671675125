import React from 'react';
import { Box, FormControlLabel, Switch, Typography, Button } from '@mui/material';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh'; // Import de l'icône de baguette magique

const MagicRecipeBuilder = ({ filters, setFilters, autoFillRecipes }) => {
  const handleToggle = (filter) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [filter]: !prevFilters[filter],
    }));
  };

  return (
    <Box
      sx={{
        mb: 2,
        p: 2,
        backgroundColor: "#EEE",
        borderRadius: 2,
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' }, // Empile les éléments sur mobile
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Box sx={{ mb: { xs: 1, sm: 0 } }}> {/* Ajout de l'espace en bas sur mobile */}
        <Typography variant="body2" fontWeight="bold">Filtrer par :</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={filters.dietaryPreferences}
              onChange={() => handleToggle('dietaryPreferences')}
            />
          }
          label={
            <Typography variant="body2">
              Régime alimentaire
            </Typography>
          }
        />
        <FormControlLabel
          control={
            <Switch
              checked={filters.intolerances}
              onChange={() => handleToggle('intolerances')}
            />
          }
          label={
            <Typography variant="body2">
              Intolérances
            </Typography>
          }
        />
      </Box>

      <Button
        variant="contained"
        color="primary"
        sx={{ fontWeight: "bold", width: { xs: "100%", sm: "auto" } }}
        onClick={autoFillRecipes}
        startIcon={<AutoFixHighIcon />} // Ajout de l'icône ici
      >
        Remplir automatiquement
      </Button>
    </Box>
  );
};

export default MagicRecipeBuilder;
