import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Alert,
  Typography,
  Paper,
  Stack,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogActions,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const AddEntity = ({
  title,
  initialValues,
  sections,
  onSubmit,
  navigateBack,
  successMessage,
  redirectAction,
}) => {
  const [formValues, setFormValues] = useState(initialValues);
  const [error, setError] = useState(null);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues({ ...formValues, [name]: checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Préparer les valeurs
    const preparedValues = {
      ...formValues,
      activityLevel: formValues.activityLevel || '', // Assurez-vous que c'est une chaîne pour activityLevel
      medicalConditions: formValues.medicalConditions || '', // Assurez-vous que c'est une chaîne pour medicalConditions
      dietaryPreferences: Array.isArray(formValues.dietaryPreferences)
        ? formValues.dietaryPreferences
        : formValues.dietaryPreferences.split(',').map((item) => item.trim()),
      intolerances: Array.isArray(formValues.intolerances)
        ? formValues.intolerances
        : formValues.intolerances.split(',').map((item) => item.trim()),
      targetMacros: {
        carbohydrates: parseFloat(formValues['targetMacros.carbohydrates']) || 0,
        proteins: parseFloat(formValues['targetMacros.proteins']) || 0,
        fats: parseFloat(formValues['targetMacros.fats']) || 0,
      },
    };

    // Supprimer les champs imbriqués inutiles
    delete preparedValues['targetMacros.carbohydrates'];
    delete preparedValues['targetMacros.proteins'];
    delete preparedValues['targetMacros.fats'];

    console.log('Prepared values (cleaned):', preparedValues);

    try {
      await onSubmit(preparedValues);
      setSuccessModalOpen(true);
    } catch (error) {
      setError(error.message || 'Une erreur est survenue');
    }
  };

  return (
    <Box
      sx={{
        px: { xs: 2, sm: 6 },
        pt: 3,
        pb: 6,
        maxWidth: '880px',
        width: { xs: '-webkit-fill-available', md: '100%' },
        margin: '0 auto',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Button variant="outlined" color="black" startIcon={<ArrowBackIcon />} onClick={navigateBack}>
          Retour
        </Button>
      </Stack>
      <Paper elevation={0} sx={{ p: { xs: 2, sm: 4 }, border: '1px solid #DDD' }}>
        <Typography variant="h3" sx={{ mb: 3 }}>
          {title}
        </Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <form onSubmit={handleSubmit}>
          {sections.map((section, sectionIndex) => (
            <Box key={sectionIndex} sx={{ mb: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                {section.title}
              </Typography>
              {section.fields.map((field, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  {field.type === 'text' && (
                    <TextField
                      label={field.label}
                      name={field.name}
                      value={formValues[field.name] || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}

                  {field.type === 'number' && (
                    <TextField
                      label={field.label}
                      name={field.name}
                      type="number"
                      value={formValues[field.name] || ''}
                      onChange={handleChange}
                      fullWidth
                    />
                  )}

                  {field.type === 'select' && (
                    <FormControl fullWidth>
                      <InputLabel>{field.label}</InputLabel>
                      <Select
                        name={field.name}
                        value={
                          formValues[field.name] !== undefined
                            ? formValues[field.name]
                            : field.multiple
                            ? []
                            : ''
                        }
                        onChange={handleChange}
                        {...(field.multiple ? { multiple: true } : {})}
                      >
                        {field.options.map((option, idx) => (
                          <MenuItem key={idx} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}

                  {field.type === 'checkbox' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues[field.name] || false}
                          onChange={handleCheckboxChange}
                          name={field.name}
                        />
                      }
                      label={field.label}
                    />
                  )}
                </Box>
              ))}
            </Box>
          ))}

          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Ajouter
          </Button>
        </form>
      </Paper>
      <Dialog open={successModalOpen}>
        <DialogTitle>{successMessage}</DialogTitle>
        <DialogActions>
          <Button onClick={redirectAction} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddEntity;
