import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import EditEntity from '../../../components/entity/EditEntity';
import CoachHeader from '../../../components/coach/CoachHeader';
import { activityLevelOptions, intoleranceOptions, dietaryPreferenceOptions } from '../../../utils/entityOptions';

const EditClient = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // eslint-disable-next-line 
  const [successModalOpen, setSuccessModalOpen] = useState(false); // Pour gérer la pop-up de succès

  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/clients/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Impossible de récupérer les informations du client.');
        }

        const data = await response.json();
        // Ajout du champ gender
        setInitialValues({
          firstName: data.firstName || '',
          lastName: data.lastName || '',
          email: data.email || '',
          phone: data.phone || '',
          age: data.age || '',
          weight: data.weight || '',
          height: data.height || '',
          gender: data.gender || '', // <-- Champ gender ajouté
          activityLevel: data.activityLevel || 'moderate',
          goals: data.goals || '',
          dietaryPreferences: data.dietaryPreferences || [],
          intolerances: data.intolerances || [],
          medicalConditions: data.medicalConditions?.join(', ') || '',
          targetMacros: {
            carbohydrates: data.targetMacros?.carbohydrates ?? 0,
            proteins: data.targetMacros?.proteins ?? 0,
            fats: data.targetMacros?.fats ?? 0,
          },
          active: data.active || false,
        });
      } catch (err) {
        console.error("Erreur lors de la récupération du client :", err.message);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [id]);

  const handleSubmit = async (values) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/clients/${id}`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json', 
          Authorization: `Bearer ${localStorage.getItem('authToken')}` 
        },
        body: JSON.stringify({
          ...values,
          medicalConditions: values.medicalConditions.split(',').map((cond) => cond.trim()),
        }),
      });
  
      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour du client.');
      }
  
      setSuccessModalOpen(true); // Affiche le modal de succès via EditEntity
    } catch (err) {
      setError(err.message);
    }
  };
  
  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <div>Erreur : {error}</div>;
  }

  // Ajout du champ gender dans la section "Informations personnelles"
  const sections = [
    {
      title: 'Informations personnelles',
      fields: [
        { name: 'firstName', label: 'Prénom', type: 'text' },
        { name: 'lastName', label: 'Nom', type: 'text' },
        { name: 'email', label: 'Email', type: 'text' },
        { name: 'phone', label: 'Téléphone', type: 'text' },
        {
          name: 'gender',
          label: 'Genre',
          type: 'select',
          multiple: false, // Sélection unique
          options: [
            { value: 'male', label: 'Masculin' },
            { value: 'female', label: 'Féminin' },
            { value: 'other', label: 'Autre' },
          ],
        },
      ],
    },
    {
      title: 'Données physiques',
      fields: [
        { name: 'age', label: 'Âge', type: 'number' },
        { name: 'weight', label: 'Poids (kg)', type: 'number' },
        { name: 'height', label: 'Taille (cm)', type: 'number' },
      ],
    },
    {
      title: 'Objectifs et santé',
      fields: [
        { name: 'activityLevel', label: 'Niveau d\'activité', type: 'select', options: activityLevelOptions },
        { name: 'goals', label: 'Objectifs', type: 'text' },
        { name: 'medicalConditions', label: 'Conditions médicales', type: 'text' },
      ],
    },
    {
      title: 'Régimes et intolérances',
      fields: [
        { name: 'intolerances', label: 'Intolérances', type: 'select', multiple: true, options: intoleranceOptions },
        { name: 'dietaryPreferences', label: 'Préférences alimentaires', type: 'select', multiple: true, options: dietaryPreferenceOptions },
      ],
    },
    {
      title: 'Macronutriments',
      fields: [
        { name: 'targetMacros.carbohydrates', label: 'Glucides (%)', type: 'number' },
        { name: 'targetMacros.proteins', label: 'Protéines (%)', type: 'number' },
        { name: 'targetMacros.fats', label: 'Lipides (%)', type: 'number' },
      ],
    },
  ];

  return (
    <>
      <CoachHeader />
      <EditEntity
        title="Modifier un client"
        initialValues={initialValues}
        sections={sections}
        onSubmit={handleSubmit}
        navigateBack={() => navigate('/coach/clients')}
        successMessage="Client mis à jour avec succès"
      />
    </>
  );
};

export default EditClient;
