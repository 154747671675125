import React, { createContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

// Création du contexte Auth
export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Stockage des données utilisateur
  const [authToken, setAuthToken] = useState(null); // Token JWT
  const [loading, setLoading] = useState(true); // Indicateur de chargement
  const navigate = useNavigate();

  // Fonction pour connecter l'utilisateur
  const login = useCallback((token) => {
    localStorage.setItem('authToken', token); // Sauvegarder le token dans localStorage
    const decoded = jwtDecode(token); // Décoder le token pour extraire les données utilisateur
    
    // Mettre à jour le user avec les informations supplémentaires
    setAuthToken(token); // Mettre à jour le token
    setUser({
      ...decoded,
      role: decoded.role, // Stocker explicitement le rôle
      isSubscribed: decoded.isSubscribed, // Assurez-vous que isSubscribed est bien récupéré
      stripeCustomerId: decoded.stripeCustomerId, // Récupérer le stripeCustomerId
    });
    setLoading(false); // Indiquer que le chargement est terminé
  }, []);
  

  // Fonction pour déconnecter l'utilisateur
  const logout = useCallback(() => {
    localStorage.removeItem('authToken'); // Supprimer le token du localStorage
    setAuthToken(null); // Réinitialiser le token
    setUser(null); // Réinitialiser les données utilisateur
    setLoading(false); // Arrêter le chargement

    Promise.resolve().then(() => {
      navigate('/login'); // Rediriger vers la page de connexion
    });
  }, [navigate]);

  // Charger les informations d'authentification depuis localStorage au démarrage
  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      const decoded = jwtDecode(token);
      if (decoded.exp * 1000 > Date.now()) {
        setAuthToken(token);
        setUser({
          ...decoded,
          role: decoded.role, // Ajout explicite du rôle
          isSubscribed: decoded.isSubscribed, // Assurez-vous que isSubscribed est bien récupéré
          stripeCustomerId: decoded.stripeCustomerId, // Récupérer le stripeCustomerId
        });
      } else {
        logout(); // Déconnexion si le token est expiré
      }
    }
    setLoading(false); // Arrêter le chargement
  }, [logout]);

  // Fonction pour rafraîchir le token (à appeler périodiquement si supporté par le backend)
  const refreshAuthToken = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
      });
  
      if (!response.ok) {
        throw new Error('Failed to refresh token');
      }
  
      const data = await response.json();
  
      // eslint-disable-next-line 
      const decoded = jwtDecode(data.token);
  
      login(data.token); // Réinitialiser les données utilisateur avec le nouveau token
    } catch (error) {
      console.error('Erreur lors du rafraîchissement du token:', error);
      logout();
    }
  }, [authToken, login, logout]);
  

  // Rafraîchissement périodique du token
  useEffect(() => {
    let interval;
    if (authToken) {
      interval = setInterval(() => {
        refreshAuthToken();
      }, 15 * 60 * 1000); // Rafraîchir toutes les 15 minutes
    }
    return () => clearInterval(interval); // Nettoyer l'intervalle lors du démontage
  }, [authToken, refreshAuthToken]);

  // Fonction pour mettre à jour le profil utilisateur
  const updateProfile = async (updatedUserInfo) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify(updatedUserInfo),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      const data = await response.json();
      setUser(data); // Mettre à jour les données utilisateur locales
    } catch (error) {
      console.error('Erreur lors de la mise à jour du profil:', error);
    }
  };

  return (
    <AuthContext.Provider value={{
      user, // Données utilisateur
      role: user?.role, // Fournir directement le rôle
      authToken, // Token JWT
      login, // Fonction de connexion
      logout, // Fonction de déconnexion
      refreshAuthToken, // Fonction de rafraîchissement de token
      updateProfile, // Fonction pour mettre à jour le profil utilisateur
      loading, // Indicateur de chargement
    }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
