import React from 'react';
import { Box, Typography, Button, IconButton, Stack, Avatar } from '@mui/material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

// Importation des icônes pour le call-to-action et les sections
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import SpaIcon from '@mui/icons-material/Spa';
import BoltIcon from '@mui/icons-material/Bolt';
import { Facebook, Instagram, Mail, Phone, Twitter } from '@mui/icons-material';

function AboutPage() {
  return (
    <>
      <Helmet>
        <title>Yoco - À propos</title>
        <meta 
          name="description" 
          content="Découvrez l'histoire, la mission et l'approche globale de YOCO pour vous rendre inarrêtable, grâce à une expertise en préparation physique, nutrition et mindset." 
        />
        <meta 
          name="keywords" 
          content="Yoco, performance, santé, préparation physique, nutrition, mindset, bien-être" 
        />
      </Helmet>

      <Box sx={{ 
        px: { xs: 2, sm: 6 }, 
        pt: 3, 
        pb: 6, 
        maxWidth: '880px', 
        width: { xs: '-webkit-fill-available', md: '100%' }, 
        margin: '0 auto' 
      }}>

        {/* Hero Section : Image de l'équipe en haut (texte à gauche, image à droite) */}
        <Box sx={{ 
          display: { xs: 'block', md: 'flex' }, 
          alignItems: 'center', 
          gap: 4, 
          mb: 6 
        }}>
          <Box sx={{ flex: 1 }}>
            <Typography variant="h2" sx={{ mb: 2, textTransform: 'uppercase' }}>
              Devenez inarrêtable.
            </Typography>
            <Typography variant="h5" sx={{ mb: 2 }}>
              YOCO vous accompagne dans vos objectifs de santé et de performance quels qu’ils soient.
            </Typography>
            <Typography variant="body1" sx={{ mb:1 }}>
              Ensemble, nous repoussons les limites, révélons votre plein potentiel et vous emmenons vers des sommets que vous n’aurez jamais imaginés atteindre.
            </Typography>
            <Typography variant="body1">
              Grâce à notre expertise en préparation physique, nutrition et mindset, nous élaborons un plan d’action sur mesure axé sur les résultats.
            </Typography>
          </Box>
          <Box sx={{ flex: 1, textAlign: { xs: 'center', md: 'right' } }}>
            <img 
              src="/static/about-image.jpg" 
              alt="Équipe YOCO" 
              style={{ width: '100%', height: 'auto', objectFit: 'cover', borderRadius: 4 }} 
            />
          </Box>
        </Box>

        {/* Section Introduction */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h4" sx={{ mb: 4 }}>
            REDÉFINIR LA CONDITION PHYSIQUE ET MENTALE
          </Typography>
          <Stack 
            direction={{ xs: 'column', md: 'row' }} 
            spacing={4} 
            justifyContent="center" 
            alignItems="center"
          >
            <Box sx={{ textAlign: 'center' }}>
              <Avatar sx={{ background:"black", margin:"0 auto" }} ><TrendingUpIcon sx={{ fontSize: 30, color: '#FFF' }} /></Avatar>
              <Typography sx={{ mt:1 }} variant="body1">Améliorer vos performances</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
            <Avatar sx={{ background:"black", margin:"0 auto" }} ><SpaIcon sx={{ fontSize: 30, color: '#FFF' }} /></Avatar>
              <Typography sx={{ mt:1 }} variant="body1">Atteindre un bien-être durable</Typography>
            </Box>
            <Box sx={{ textAlign: 'center' }}>
            <Avatar sx={{ background:"black", margin:"0 auto" }} ><BoltIcon sx={{ fontSize: 30, color: '#FFF' }} /></Avatar>
              <Typography sx={{ mt:1 }} variant="body1">Booster votre mental</Typography>
            </Box>
          </Stack>
        </Box>

        {/* Section "LES PILIERS DE YOCO" */}
        <Box sx={{ mb: 8 }}>
          <Typography variant="h2" sx={{ mb: 4 }}>
            LES PILIERS DE YOCO
          </Typography>

          {/* Pilier 1 : Le Sport */}
          <Box sx={{ mb: 6 }}>
              <Typography  sx={{ mb:2 }} variant="h4">
                Le Sport : l'art de la préparation physique.
              </Typography>
            <Typography variant="body1">
              En personal training ou en groupe, chaque séance est conçue pour améliorer vos performances. Disponible en présentiel ou en ligne, notre approche allie des méthodes modernes et adaptées à votre niveau, pour des résultats concrets.
            </Typography>
          </Box>

          {/* Pilier 2 : La Diet */}
          <Box sx={{ mb: 6}}>
              <Typography  sx={{ mb:2 }} variant="h4">
                La Diet : la nutrition, fondation de la performance
              </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              La nutrition n’est pas qu’un simple outil : c’est la clé de votre santé et de vos performances physiques et mentales.
            </Typography>
            <Typography variant="body1" sx={{ mb: 1 }}>
              Avec notre plateforme innovante, accédez à :
            </Typography>
            <Box sx={{ ml: 6, mb: 1 }}>
              <Typography variant="body1">• Des recommandations nutritionnelles personnalisées</Typography>
              <Typography variant="body1">• Des solutions adaptées (perte de poids, prise de masse, énergie optimisée ou performance)</Typography>
            </Box>
            <Typography variant="body1" >
              En combinant science et technologie, pour particuliers ou professionnels, nous transformons votre alimentation vers une nouvelle dimension.
            </Typography>
          </Box>

          {/* Pilier 3 : Le Mindset */}
          <Box sx={{ mb: 6  }}>
              <Typography sx={{ mb:2 }} variant="h4">
                Le mindset : renforcez l’esprit d’équipe et (re)devenez confiant
              </Typography>
            <Typography variant="body1">
              Offrez à vos équipes une expérience unique, alliant défi physique et cohésion collective. Entraînements personnalisés pour individuels ou petits groupes, et des événements uniques (Olympiades, stages de survie, ateliers santé & bien-être, QVT) pour développer votre résilience mentale et votre connaissance de soi.
            </Typography>
          </Box>
        </Box>

        {/* Founder Section */}
        <Box 
          sx={{ 
            mb: 8, 
            display: 'flex', 
            flexDirection: { xs: 'column', md: 'row' }, 
            alignItems: 'center', 
            gap: 4 
          }}
        >
          <Box sx={{ flex: 1 }}>
            <img 
              src="/static/thomas.jpg" 
              alt="Thomas Fernandez" 
              style={{ width: '100%', maxWidth: '400px', height: 'auto', objectFit: 'cover', borderRadius: 4 }} 
            />
          </Box>
          <Box sx={{ flex: 2 }}>
          <Typography variant="h3">
              Thomas Fernandez
            </Typography>
            <Typography variant="h6" sx={{ mb: 1 }}>
              Fondateur de YOCO
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, fontStyle: 'italic' }}>
              “YOCO puise sa force dans une expérience forgée par le terrain et des rencontres marquantes. De là est née une méthode complète et puissante. Un équilibre sur-mesure, pour vous rendre inarrêtable.”
            </Typography>

          </Box>
        </Box>

        {/* Call to Action Section */}
        <Box 
          sx={{ 
            backgroundColor: '#000', 
            color: '#fff', 
            width: '100%', 
            py: 5, 
            textAlign: 'center', 
            borderRadius: 2, 
            mt: 8 
          }}
        >
          <Typography variant="h2" sx={{ mb: 3 }}>
            Prêt à nous rejoindre ?
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.1em', mb: 4, px: { xs: 2, sm: 15 } }}>
            Contactez-nous dès aujourd'hui et faites le premier pas vers l'atteinte de vos objectifs.
          </Typography>
          <Button 
            variant="contained" 
            component={Link} 
            to="/contact" 
            color="primary" 
            size="large"
          >
            Contactez-nous
          </Button>
        </Box>

        {/* Social Media Section */}
        <Box sx={{ mt: 5, textAlign: 'center', py: 3, width: '100%' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Suivez-nous sur les réseaux sociaux
          </Typography>
          <Box>
            <IconButton sx={{ color: '#000', fontSize: 20 }}>
              <Facebook fontSize="inherit" />
            </IconButton>
            <IconButton sx={{ color: '#000', fontSize: 20 }}>
              <Instagram fontSize="inherit" />
            </IconButton>
            <IconButton sx={{ color: '#000', fontSize: 20 }}>
              <Twitter fontSize="inherit" />
            </IconButton>
            <IconButton component={Link} to="/contact" sx={{ color: '#000', fontSize: 20 }}>
              <Mail fontSize="inherit" />
            </IconButton>
            <IconButton sx={{ color: '#000', fontSize: 20 }}>
              <Phone fontSize="inherit" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default AboutPage;
