import React from "react";
import { Box, Typography, Chip } from "@mui/material";

const DIETARY_TRANSLATIONS = {
  vegetarian: "Végétarien",
  vegan: "Vegan",
  "gluten-free": "Sans gluten",
  "dairy-free": "Sans produits laitiers",
  paleo: "Paléo",
  keto: "Kéto",
  other: "Autre",
};

const INTOLERANCE_TRANSLATIONS = {
  gluten: "Gluten",
  lactose: "Lactose",
  nuts: "Noix",
  shellfish: "Fruits de mer",
  soy: "Soja",
  eggs: "Œufs",
  fish: "Poisson",
  pork: "Porc",
  beef: "Bœuf",
  chicken: "Poulet",
  turkey: "Dinde",
  corn: "Maïs",
  sesame: "Sésame",
  mustard: "Moutarde",
  peanuts: "Arachides",
  citrus: "Agrumes",
  mushrooms: "Champignons",
  legumes: "Légumineuses",
  other: "Autre",
};

const UserInformation = ({ selectedClient, userPreferences }) => {
  return (
    <Box sx={{ border: "1px solid #DDD", borderRadius: 1, mb: 2, p: 2 }}>
      {selectedClient && userPreferences ? (
        <>
          <Typography sx={{ mb: 1 }} variant="h5">
            {selectedClient.firstName} {selectedClient.lastName}
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            {selectedClient.goals && (
              <Typography variant="body2" sx={{ mr: 4 }}>
                <strong>Objectif</strong> <Chip label={selectedClient.goals} />
              </Typography>
            )}
            {userPreferences.length > 0 && (
              <Typography variant="body2" sx={{ mr: 4 }}>
                <strong>Régime alimentaire</strong>{" "}
                {userPreferences.map((pref) => (
                  <Chip
                    key={pref}
                    label={DIETARY_TRANSLATIONS[pref] || pref}
                    sx={{ mr: 1 }}
                  />
                ))}
              </Typography>
            )}
            {selectedClient.intolerances &&
              selectedClient.intolerances.length > 0 && (
                <Typography variant="body2">
                  <strong>Intolérances</strong>{" "}
                  {selectedClient.intolerances.map((intol) => (
                    <Chip
                      key={intol}
                      label={INTOLERANCE_TRANSLATIONS[intol] || intol}
                      sx={{ mr: 1 }}
                    />
                  ))}
                </Typography>
              )}

          </Box>
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            minHeight: "75px",
            alignItems: "center",
          }}
        >
          <Typography textTransform="uppercase" variant="body2">
            Aucun client sélectionné
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default UserInformation;
