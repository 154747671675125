import React from 'react';
import { useNavigate } from 'react-router-dom';
import AddEntity from '../../../components/entity/AddEntity';
import CoachHeader from '../../../components/coach/CoachHeader';
import { activityLevelOptions, intoleranceOptions, dietaryPreferenceOptions } from '../../../utils/entityOptions';

const AddClient = () => {
  const navigate = useNavigate();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    age: '',
    weight: '',
    height: '',
    gender: '', // Champ gender ajouté
    activityLevel: 'moderate',
    goals: '',
    dietaryPreferences: [],
    intolerances: [],
    medicalConditions: '',
    targetMacros: {
      carbohydrates: '',
      proteins: '',
      fats: '',
    },
  };

  const sections = [
    {
      title: 'Informations personnelles',
      fields: [
        { name: 'firstName', label: 'Prénom', type: 'text' },
        { name: 'lastName', label: 'Nom', type: 'text' },
        { name: 'email', label: 'Email', type: 'text' },
        { name: 'phone', label: 'Téléphone', type: 'text' },
        {
          name: 'gender',
          label: 'Genre',
          type: 'select',
          multiple: false, // Indique que la sélection n'est pas multiple
          options: [
            { value: 'male', label: 'Masculin' },
            { value: 'female', label: 'Féminin' },
            { value: 'other', label: 'Autre' },
          ],
        },
      ],
    },
    {
      title: 'Données physiques',
      fields: [
        { name: 'age', label: 'Âge', type: 'number' },
        { name: 'weight', label: 'Poids (kg)', type: 'number' },
        { name: 'height', label: 'Taille (cm)', type: 'number' },
      ],
    },
    {
      title: 'Objectifs et santé',
      fields: [
        { name: 'activityLevel', label: 'Niveau d\'activité', type: 'select', options: activityLevelOptions },
        { name: 'goals', label: 'Objectifs', type: 'text' },
        { name: 'medicalConditions', label: 'Conditions médicales', type: 'text' },
      ],
    },
    {
      title: 'Régimes et intolérances',
      fields: [
        { name: 'intolerances', label: 'Intolérances', type: 'select', multiple: true, options: intoleranceOptions },
        { name: 'dietaryPreferences', label: 'Préférences alimentaires', type: 'select', multiple: true, options: dietaryPreferenceOptions },
      ],
    },
    {
      title: 'Macronutriments',
      fields: [
        { name: 'targetMacros.carbohydrates', label: 'Glucides (%)', type: 'number' },
        { name: 'targetMacros.proteins', label: 'Protéines (%)', type: 'number' },
        { name: 'targetMacros.fats', label: 'Lipides (%)', type: 'number' },
      ],
    },
  ];

  const handleSubmit = async (values) => {
    const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

    const response = await fetch(`${BACKEND_URL}/api/users/clients`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('authToken')}`,
      },
      body: JSON.stringify(values),
    });

    if (!response.ok) {
      throw new Error('Échec de l\'ajout du client');
    }
  };

  return (
    <>
      <CoachHeader />
      <AddEntity
        title="Ajouter un client"
        initialValues={initialValues}
        sections={sections}
        onSubmit={handleSubmit}
        navigateBack={() => navigate('/coach/clients')}
        successMessage="Client ajouté avec succès"
        redirectAction={() => navigate('/coach/clients')}
      />
    </>
  );
};

export default AddClient;
