import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Box, CircularProgress, Typography } from '@mui/material';
import CoachHeader from '../../../components/coach/CoachHeader';
import GeneralInfo from '../../../components/dashboard/GeneralInfo';
import Recommendations from '../../../components/dashboard/Recommendations';
import DayPlans from '../../../components/dashboard/DayPlans';
import ShoppingList from '../../../components/dashboard/ShoppingList';
import { createShoppingList } from '../../../utils/createShoppingList';
import { adjustRecipeMacros } from '../../../utils/adjustRecipeMacros';

const CoachPlanView = () => {
  const { clientId, weekPlanId } = useParams();
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shoppingList, setShoppingList] = useState([]);

  const adjustDayPlansRecipes = async (dayPlans, targetMacros) => {
    const adjustedRecipes = [];
    for (const dayPlan of dayPlans) {
      for (const meal of Object.values(dayPlan.meals)) {
        if (meal) {
          const adjustedRecipe = await adjustRecipeMacros(meal, targetMacros, dayPlan.dayType);
          adjustedRecipes.push(adjustedRecipe);
        }
      }
    }
    return adjustedRecipes;
  };

  const loadData = async (data) => {
    try {
      const adjustedRecipes = await adjustDayPlansRecipes(data.dayPlans, data.user.targetMacros);
      const generatedList = createShoppingList(adjustedRecipes);
      setShoppingList(generatedList);

      localStorage.setItem('adjustedRecipes', JSON.stringify(adjustedRecipes));
      localStorage.setItem('targetMacros', JSON.stringify(data.user.targetMacros));
      const dayTypes = data.dayPlans.map(day => day.dayType);
      localStorage.setItem('dayTypes', JSON.stringify(dayTypes));
      const recipeIds = data.dayPlans
        .map(dayPlan =>
          Object.values(dayPlan.meals).map(meal => (meal ? meal._id : null))
        )
        .flat();
      localStorage.setItem('recipeIds', JSON.stringify(recipeIds));
    } catch (err) {
      console.error('Erreur lors du calcul des recettes ajustées:', err.message);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchPlanDetails = async () => {
        try {
          const response = await fetch(
            `${process.env.REACT_APP_BACKEND_URL}/api/weekplans/clients/${clientId}/${weekPlanId}`,
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
              },
            }
          );
      
          if (!response.ok) {
            throw new Error('Erreur lors de la récupération des données du programme.');
          }
      
          const data = await response.json();
      
          // Ajoute le log ici pour inspecter les données renvoyées par l'API
          // console.log('Plan Data:', data);
      
          setPlan(data);
      
          const storedMacros = JSON.parse(localStorage.getItem('targetMacros'));
          const storedDayTypes = JSON.parse(localStorage.getItem('dayTypes'));
          const storedRecipeIds = JSON.parse(localStorage.getItem('recipeIds'));
      
          const currentRecipeIds = data.dayPlans
            .map(dayPlan =>
              Object.values(dayPlan.meals).map(meal => (meal ? meal._id : null))
            )
            .flat();
      
          if (
            storedMacros &&
            JSON.stringify(storedMacros) === JSON.stringify(data.user.targetMacros) &&
            storedDayTypes &&
            JSON.stringify(storedDayTypes) === JSON.stringify(data.dayPlans.map(day => day.dayType)) &&
            storedRecipeIds &&
            JSON.stringify(storedRecipeIds) === JSON.stringify(currentRecipeIds)
          ) {
            const storedRecipes = JSON.parse(localStorage.getItem('adjustedRecipes'));
            setShoppingList(createShoppingList(storedRecipes));
            setLoading(false);
          } else {
            await loadData(data);
          }
        } catch (err) {
          console.error('Erreur lors de la récupération des données :', err.message);
          setError(err.message);
          setLoading(false);
        }
      };
      

    fetchPlanDetails();
    // eslint-disable-next-line 
  }, [clientId, weekPlanId]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Box>
    );
  }

  return (
    <>
      <CoachHeader />
      <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>
        <GeneralInfo
          user={plan.user}
          dietaryPreferences={plan.user?.dietaryPreferences}
          goals={plan.user?.goals}
          allergies={plan.user?.allergies}
          summary={plan.summary}
          targetMacros={plan.user?.targetMacros}
          planId={plan._id}
        />
        <Recommendations recommendations={plan.recommendations} />
        <DayPlans dayPlans={plan.dayPlans} targetMacros={plan.user?.targetMacros} />
        <ShoppingList shoppingList={shoppingList} />
      </Box>
    </>
  );
};

export default CoachPlanView;
