import React, { useState, useEffect } from 'react';
import { Box, Dialog, DialogTitle, DialogActions, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ClientSelection from '../../../components/plans/ClientSelection';
import RecipeSelection from '../../../components/plans/RecipeSelection';
import Recommendations from '../../../components/plans/Recommendations';
import CoachHeader from '../../../components/coach/CoachHeader';

const CoachCreateWeekPlan = () => {
  const [clients, setClients] = useState([]);
  const [recipes, setRecipes] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [clientDetails, setClientDetails] = useState(null);
  const [weekNumber, setWeekNumber] = useState('');
  const [dayPlans, setDayPlans] = useState(
    [
      { day: 'Monday', meals: { breakfast: null, morningSnack: null, lunch: null, snack: null, dinner: null } },
      { day: 'Tuesday', meals: { breakfast: null, morningSnack: null, lunch: null, snack: null, dinner: null } },
      { day: 'Wednesday', meals: { breakfast: null, morningSnack: null, lunch: null, snack: null, dinner: null } },
      { day: 'Thursday', meals: { breakfast: null, morningSnack: null, lunch: null, snack: null, dinner: null } },
      { day: 'Friday', meals: { breakfast: null, morningSnack: null, lunch: null, snack: null, dinner: null } },
      { day: 'Saturday', meals: { breakfast: null, morningSnack: null, lunch: null, snack: null, dinner: null } },
      { day: 'Sunday', meals: { breakfast: null, morningSnack: null, lunch: null, snack: null, dinner: null } },
    ]
  );

  const [recommendations, setRecommendations] = useState({
    sleep: '',
    alcohol: '',
    dairyProducts: '',
    hydration: '',
    physicalActivity: '',
    stressManagement: '',
    nutrition: '',
    others: '',
  });
  const [summary, setSummary] = useState('');
  const [step, setStep] = useState(1);
  const [successModalOpen, setSuccessModalOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch clients from API for the logged-in coach
    const fetchClients = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/clients`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        const data = await response.json();
        setClients(data);
      } catch (error) {
        console.error('Error fetching clients', error);
      }
    };
    fetchClients();

    // Fetch recipes from API
    const fetchRecipes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/recipes`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
        const data = await response.json();
        setRecipes(data);
      } catch (error) {
        console.error('Error fetching recipes', error);
      }
    };
    fetchRecipes();
  }, []);

  useEffect(() => {
    if (selectedClient) {
      // Fetch selected client details from API
      const fetchClientDetails = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/clients/${selectedClient}`, {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('authToken')}`,
            },
          });
          const data = await response.json();
          setClientDetails(data);
        } catch (error) {
          console.error('Error fetching client details', error);
        }
      };
      fetchClientDetails();
    }
  }, [selectedClient]);

  const handleDayPlanChange = (dayIndex, meal, recipeId) => {
    const updatedDayPlans = [...dayPlans];
    if (!updatedDayPlans[dayIndex]) {
      updatedDayPlans[dayIndex] = { day: '', meals: {} };
    }
    updatedDayPlans[dayIndex].meals[meal] = recipeId;
    setDayPlans(updatedDayPlans);
  };

  const handleCreateWeekPlan = async () => {
    try {
      const validDayPlans = dayPlans.map((dayPlan) => ({
        ...dayPlan,
        meals: {
          breakfast: dayPlan.meals.breakfast || null,
          morningSnack: dayPlan.meals.morningSnack || null,
          lunch: dayPlan.meals.lunch || null,
          snack: dayPlan.meals.snack || null,
          dinner: dayPlan.meals.dinner || null,
        },
      }));
      const newWeekPlan = {
        user: selectedClient,
        weekNumber,
        dayPlans: validDayPlans,
        recommendations,
        summary,
      };
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/weekplans/clients/${selectedClient}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(newWeekPlan),
      });

      if (!response.ok) {
        throw new Error('Failed to create week plan');
      }

      setSuccessModalOpen(true);
    } catch (error) {
      console.error('Error creating week plan', error);
    }
  };

  const nextStep = () => {
    if (step === 1 && !selectedClient) {
      alert('Please select a client before proceeding.');
      return;
    }
    setStep((prevStep) => prevStep + 1);
  };

  const prevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleCloseSuccessModal = () => {
    setSuccessModalOpen(false);
    navigate('/coach/plans');
  };

  return (
    <>
      <CoachHeader />

      <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>
        {step === 1 && (
          <ClientSelection
            clients={clients}
            selectedClient={clientDetails}
            setSelectedClient={setSelectedClient}
            summary={summary}
            userPreferences={clientDetails?.dietaryPreferences ?? []}
            setSummary={setSummary}
            nextStep={nextStep}
          />
        )}

        {step === 2 && selectedClient && clientDetails && (
          <RecipeSelection
            weekNumber={weekNumber}
            setWeekNumber={setWeekNumber}
            dayPlans={dayPlans}
            setDayPlans={setDayPlans}
            recipes={recipes}
            handleDayPlanChange={handleDayPlanChange}
            selectedClient={clientDetails}
            userPreferences={clientDetails?.dietaryPreferences ?? []}
            nextStep={nextStep}
            prevStep={prevStep}
          />
        )}

        {step === 3 && (
          <Recommendations
            recommendations={recommendations}
            setRecommendations={setRecommendations}
            handleCreateWeekPlan={handleCreateWeekPlan}
            selectedClient={clientDetails}
            userPreferences={clientDetails?.dietaryPreferences ?? []}
            prevStep={prevStep}
          />
        )}

        <Dialog open={successModalOpen} onClose={handleCloseSuccessModal}>
          <DialogTitle>Programmé créé avec succès</DialogTitle>
          <DialogActions>
            <Button onClick={handleCloseSuccessModal} color="primary">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default CoachCreateWeekPlan;
