import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Button } from '@mui/material';
import { AuthContext } from '../../../context/AuthContext'; // Contexte d'authentification
import { useNavigate } from 'react-router-dom'; // Pour la redirection

const CheckoutCancelPage = () => {
  const { user, refreshAuthToken } = useContext(AuthContext); // Récupérer l'utilisateur et la fonction de rafraîchissement du token
  const [isLoading, setIsLoading] = useState(true); // État pour le chargement
  const [isSubscribed, setIsSubscribed] = useState(false); // État pour vérifier si l'abonnement est actif
  const navigate = useNavigate(); // Pour la redirection

  useEffect(() => {
    const checkSubscriptionStatus = async () => {
      try {
        // Rafraîchir le token pour obtenir le statut d'abonnement à jour
        await refreshAuthToken();

        // Vérifier si l'utilisateur est abonné
        if (user && user.isSubscribed) {
          setIsSubscribed(true);
          navigate('/coach/profile'); // Rediriger si l'abonnement est actif
        } else {
          setIsSubscribed(false);
        }
      } catch (error) {
        console.error('Erreur lors de la vérification de l\'abonnement:', error);
      } finally {
        setIsLoading(false); // Fin du chargement
      }
    };

    checkSubscriptionStatus(); // Appeler la fonction de vérification
  }, [user, refreshAuthToken, navigate]);

  const handleRetry = () => {
    navigate('/coach/checkout'); // Redirection vers la page de paiement
  };

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
      >
        <Typography variant="h6">Vérification en cours...</Typography>
      </Box>
    );
  }

  if (isSubscribed) {
    return null; // Rien à afficher car l'utilisateur est redirigé
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '78vh',
        textAlign: 'center',
        padding: '20px',
      }}
    >
      <Typography variant="h3" sx={{ marginBottom: '10px' }}>
        Opération annulée
      </Typography>
      <Typography variant="body1" sx={{ marginBottom: '20px' }}>
        Votre abonnement n'a pas été finalisé. Veuillez réessayer.
      </Typography>
      <Button variant="contained" color="primary" onClick={handleRetry}>
        Réessayer
      </Button>
    </Box>
  );
};

export default CheckoutCancelPage;