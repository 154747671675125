// utils/translate.js

// Traduction des intolérances
const intoleranceTranslations = {
  gluten: "Gluten",
  lactose: "Lactose",
  nuts: "Noix",
  shellfish: "Fruits de mer",
  soy: "Soja",
  eggs: "Œufs",
  fish: "Poisson",
  pork: "Porc",
  beef: "Bœuf",
  chicken: "Poulet",
  turkey: "Dinde",
  corn: "Maïs",
  sesame: "Sésame",
  mustard: "Moutarde",
  peanuts: "Arachides",
  citrus: "Agrumes",
  mushrooms: "Champignons",
  legumes: "Légumineuses",
  other: "Autre",
};

/**
 * Traduit une intolérance de l'anglais au français.
 * @param {string} intolerance - Intolérance en anglais.
 * @returns {string} - Intolérance traduite en français.
 */
export const translateIntolerance = (intolerance) => {
  return intoleranceTranslations[intolerance] || intolerance;
};

/**
 * Traduit une liste d'intolérances de l'anglais au français.
 * @param {string[]} intolerances - Liste d'intolérances en anglais.
 * @returns {string[]} - Liste d'intolérances traduites en français.
 */
export const translateIntolerances = (intolerances) => {
  return intolerances.map((intolerance) => translateIntolerance(intolerance));
};

// Traduction des niveaux d'activité
const activityLevelTranslations = {
  sedentary: "Sédentaire",
  moderate: "Modéré",
  active: "Actif",
};

/**
 * Traduit un niveau d'activité de l'anglais au français.
 * @param {string} activityLevel - Niveau d'activité en anglais.
 * @returns {string} - Niveau d'activité traduit en français.
 */
export const translateActivityLevel = (activityLevel) => {
  return activityLevelTranslations[activityLevel] || activityLevel;
};

// Traduction des préférences alimentaires
const dietaryPreferenceTranslations = {
  vegetarian: "Végétarien",
  vegan: "Vegan",
  "gluten-free": "Sans gluten",
  "dairy-free": "Sans produits laitiers",
  paleo: "Paléo",
  keto: "Kéto",
  other: "Autre",
};

/**
 * Traduit une préférence alimentaire de l'anglais au français.
 * @param {string} preference - Préférence alimentaire en anglais.
 * @returns {string} - Préférence alimentaire traduite en français.
 */
export const translateDietaryPreference = (preference) => {
  return dietaryPreferenceTranslations[preference] || preference;
};

/**
 * Traduit une liste de préférences alimentaires de l'anglais au français.
 * @param {string[]} preferences - Liste de préférences alimentaires en anglais.
 * @returns {string[]} - Liste de préférences alimentaires traduites en français.
 */
export const translateDietaryPreferences = (preferences) => {
  return preferences.map((preference) => translateDietaryPreference(preference));
};