import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Alert,
  Typography,
  Paper,
  Stack,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const EditEntity = ({ 
  title, 
  initialValues, 
  sections, 
  onSubmit, 
  navigateBack, 
  successMessage 
}) => {
  const [formValues, setFormValues] = useState({});
  const [error, setError] = useState(null); // Pour gérer les erreurs
  const [successModalOpen, setSuccessModalOpen] = useState(false); // Pour gérer la pop-up de succès

  useEffect(() => {
    if (initialValues) {
      setFormValues((prevState) => ({
        ...initialValues,
        activityLevel: initialValues.activityLevel || '',  // Assurer que c'est une chaîne pour activityLevel
        medicalConditions: Array.isArray(initialValues.medicalConditions)
          ? initialValues.medicalConditions.join(', ')  // Si tableau, les afficher séparées par des virgules
          : initialValues.medicalConditions || '',  // Sinon c'est une chaîne vide
      }));
    }
  }, [initialValues]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes('.')) {
      // Gestion des sous-clés comme targetMacros.carbohydrates
      const [parentKey, childKey] = name.split('.');
      setFormValues((prev) => ({
        ...prev,
        [parentKey]: {
          ...prev[parentKey],
          [childKey]: value,
        },
      }));
    } else {
      setFormValues((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    // Vérifiez les valeurs dans formValues
    console.log('formValues:', formValues);
  
    // Préparation des valeurs
    const preparedValues = {
      ...formValues,
      activityLevel: formValues.activityLevel || '',  // Assurez-vous que c'est une chaîne pour activityLevel
      medicalConditions: formValues.medicalConditions || '',  // Assurez-vous que c'est une chaîne pour medicalConditions
      dietaryPreferences: Array.isArray(formValues.dietaryPreferences)
        ? formValues.dietaryPreferences
        : formValues.dietaryPreferences.split(',').map((item) => item.trim()),
      intolerances: Array.isArray(formValues.intolerances)
        ? formValues.intolerances
        : formValues.intolerances.split(',').map((item) => item.trim()),
      targetMacros: {
        carbohydrates: parseFloat(formValues.targetMacros?.carbohydrates) || 0,
        proteins: parseFloat(formValues.targetMacros?.proteins) || 0,
        fats: parseFloat(formValues.targetMacros?.fats) || 0,
      },
    };
  
    // Vérifiez la valeur après préparation
    console.log('Prepared values (cleaned):', preparedValues);
  
    try {
      await onSubmit(preparedValues);
      setSuccessModalOpen(true);
    } catch (error) {
      setError(error.message || 'Une erreur est survenue');
    }
  };
  
  return (
    <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Button variant="outlined" color="black" startIcon={<ArrowBackIcon />} onClick={navigateBack}>
          Retour
        </Button>
      </Stack>
      <Paper elevation={0} sx={{ p: { xs: 2, sm: 4 }, border: '1px solid #DDD' }}>
        <Typography variant="h4" sx={{ mb: 3 }}>{title}</Typography>
        {error && <Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>}
        <form onSubmit={handleSubmit}>
          {sections.map((section, sectionIndex) => (
            <Box key={sectionIndex} sx={{ mb: 4 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>{section.title}</Typography>
              {section.fields.map((field, index) => (
                <Box key={index} sx={{ mb: 2 }}>
                  {field.type === 'text' && (
                    <TextField
                      label={field.label}
                      name={field.name}
                      value={formValues[field.name] || ''}  // Si vide, utilise une chaîne vide
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                  {field.type === 'number' && (
                    <TextField
                      label={field.label}
                      name={field.name}
                      type="number"
                      value={
                        field.name.includes('.')
                          ? formValues[field.name.split('.')[0]]?.[field.name.split('.')[1]] || ''
                          : formValues[field.name] || ''
                      }
                      onChange={handleChange}
                      fullWidth
                    />
                  )}
                  {field.type === 'select' && (
                    <FormControl fullWidth>
                      <InputLabel>{field.label}</InputLabel>
                      <Select
                        name={field.name}
                        value={
                          field.multiple
                            ? (Array.isArray(formValues[field.name]) ? formValues[field.name] : [])
                            : (formValues[field.name] !== undefined ? formValues[field.name] : '')
                        }
                        onChange={handleChange}
                        {...(field.multiple ? { multiple: true } : {})}
                      >
                        {field.options.map((option, idx) => (
                          <MenuItem key={idx} value={option.value}>
                            {option.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                  {field.type === 'checkbox' && (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={formValues[field.name] || false}
                          onChange={handleCheckboxChange}
                          name={field.name}
                        />
                      }
                      label={field.label}
                    />
                  )}
                </Box>
              ))}
            </Box>
          ))}
  
          <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
            Sauvegarder
          </Button>
        </form>
      </Paper>
      <Dialog open={successModalOpen}>
        <DialogTitle>{successMessage}</DialogTitle>
        <DialogActions>
          <Button onClick={navigateBack} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditEntity;
