import React, { useContext, useEffect } from 'react';
import { Box, CircularProgress } from '@mui/material';
import { Navigate, Outlet } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';


// Route publique - accessible uniquement aux utilisateurs non connectés
export const PublicRoute = () => {
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    console.log("PublicRoute - User:", user);
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return user ? <Navigate to="/" /> : <Outlet />;
};

// Route utilisateur - accessible uniquement aux utilisateurs connectés
export const UserRoute = () => {
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    console.log("UserRoute - User:", user);
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return user ? <Outlet /> : <Navigate to="/home" />;
};

// Route administrateur - accessible uniquement aux administrateurs
export const AdminRoute = () => {
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    // console.log("AdminRoute - User:", user);
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    // console.log("AdminRoute - Redirection vers /login");
    return <Navigate to="/home" />;
  }

  if (user.role !== 'admin') {
    // console.log(`AdminRoute - Redirection: utilisateur avec rôle (${user.role})`);
    if (user.role === 'coach') {
      // Redirige vers /clients si l'utilisateur est un coach
      return <Navigate to="/coach/clients" />;
    }
    // Redirige vers /week pour les autres rôles
    return <Navigate to="/week" />;
  }

  return <Outlet />;
};

// Route coach - accessible uniquement aux coachs
export const CoachRoute = () => {
  const { user, loading } = useContext(AuthContext);

  useEffect(() => {
    // console.log("CoachRoute - User:", user);
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    // console.log("CoachRoute - Redirection vers /login");
    return <Navigate to="/login" />;
  }

  if (user.role !== 'coach') {
    // console.log("CoachRoute - Redirection vers / (l'utilisateur n'est pas coach)");
    return <Navigate to="/home" />;
  }

  return <Outlet />;
};

// Route coach abonné - accessible uniquement aux coachs abonnés
// Route coach abonné - accessible uniquement aux coachs abonnés
export const SubscribedCoachRoute = () => {
  const { user, loading } = useContext(AuthContext); // Utilisez user pour accéder à isSubscribed

  useEffect(() => {
    console.log("SubscribedCoachRoute - User:", user);
    console.log("SubscribedCoachRoute - isSubscribed:", user?.isSubscribed); // Accédez à isSubscribed via user
  }, [user]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (!user) {
    console.log("SubscribedCoachRoute - Redirection vers /login");
    return <Navigate to="/login" />;
  }

  if (user.role !== 'coach') {
    console.log("SubscribedCoachRoute - Redirection vers / (l'utilisateur n'est pas coach)");
    return <Navigate to="/home" />;
  }

  // Vérifiez si le coach est abonné via user.isSubscribed
  if (!user.isSubscribed) {
    console.log("SubscribedCoachRoute - Redirection vers /coach/profile (non abonné)");
    return <Navigate to="/coach/checkout" />;
  }

  console.log("SubscribedCoachRoute - Accès autorisé");
  return <Outlet />;
};

const ProtectedRoutes = { PublicRoute, UserRoute, AdminRoute, CoachRoute, SubscribedCoachRoute };
export default ProtectedRoutes;
