import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle'; // Icône de validation
import { useNavigate } from 'react-router-dom';

const CheckoutSuccessPage = () => {
  const [loading, setLoading] = useState(true); // État pour le chargement
  const [error, setError] = useState(null); // État pour les erreurs
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // État pour le statut de l'abonnement
  const navigate = useNavigate();

  // Fonction pour rafraîchir le token et vérifier l'abonnement
  const verifySubscription = async () => {
    try {
      // Rafraîchir le token
      const refreshResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/refresh-token`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (!refreshResponse.ok) {
        throw new Error('Failed to refresh token');
      }

      const { token } = await refreshResponse.json();
      localStorage.setItem('authToken', token); // Mettre à jour le token dans le localStorage

      // Vérifier le statut de l'abonnement
      const profileResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/profile`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!profileResponse.ok) {
        throw new Error('Failed to fetch profile');
      }

      const profileData = await profileResponse.json();
      setSubscriptionStatus(profileData.isSubscribed ? 'active' : 'inactive'); // Mettre à jour le statut de l'abonnement
    } catch (error) {
      console.error('Erreur lors de la vérification de l\'abonnement:', error);
      setError('Erreur lors de la vérification de l\'abonnement. Veuillez réessayer.');
    } finally {
      setLoading(false); // Arrêter le chargement
    }
  };

  // Vérifier l'abonnement au chargement de la page
  useEffect(() => {
    verifySubscription();
  }, []);

  // Rediriger vers la page d'accueil après 5 secondes
  useEffect(() => {
    if (subscriptionStatus === 'active') {
      const timer = setTimeout(() => {
        navigate('/coach/profile'); // Rediriger vers la page de profil après 5 secondes
      }, 5000);

      return () => clearTimeout(timer); // Nettoyer le timer
    }
  }, [subscriptionStatus, navigate]);

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '78vh',
        textAlign: 'center',
      }}
    >
      <Box>
        {loading ? (
          <>
            <CircularProgress size={60} sx={{ mb: 3 }} />
            <Typography variant="h5" sx={{ mb: 2 }}>
              Vérification de l'abonnement...
            </Typography>
          </>
        ) : error ? (
          <Typography variant="h5" color="error">
            {error}
          </Typography>
        ) : subscriptionStatus === 'active' ? (
          <>
            <CheckCircleIcon sx={{ fontSize: 50, color: 'green', mb: 1 }} />
            <Typography variant="h3" sx={{ mb: 2 }}>
              Abonnement validé 
            </Typography>
            <Typography variant="body1">
              Vous serez redirigé dans quelques secondes...
            </Typography>
          </>
        ) : (
          <Typography variant="h5" color="error">
            L'abonnement n'a pas été activé. Veuillez contacter le support.
          </Typography>
        )}
      </Box>
    </Box>
  );
};

export default CheckoutSuccessPage;