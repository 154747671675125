import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  Drawer,
  Chip,
  List,
  ListItem,
  ListItemText,
  ListItemButton,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
} from '@mui/material';
import { styled } from '@mui/system';
import UserMin from '../../components/plans/UserMin';
import AddIcon from '@mui/icons-material/Add';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import MagicRecipeBuilder from './MagicRecipeBuilder';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

const CalendarCell = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.divider,
  padding: theme.spacing(2),
  textAlign: 'center',
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
}));

// Dictionnaire pour la traduction
const mealTranslations = {
  breakfast: 'Petit-déjeuner',
  morningSnack: 'Encas matin',
  lunch: 'Déjeuner',
  snack: 'Encas journée',
  dinner: 'Dîner',
};

// Fonction utilitaire pour traduire une clé anglaise en français
const translateMealToFrench = (mealKey) => mealTranslations[mealKey] || mealKey;

const CATEGORY_TRANSLATIONS = {
  vegetarian: "Végétarien",
  vegan: "Vegan",
  "gluten-free": "Sans gluten",
  "dairy-free": "Sans produits laitiers",
  paleo: "Paléo",
  keto: "Kéto",
  other: "Autre",
};

const getMealKeyFromFrench = (frenchMeal) => {
  const normalizedFrenchMeal = frenchMeal.trim().toLowerCase();

  // Cas particulier pour morningSnack
  if (normalizedFrenchMeal === 'encas matin') {
    return 'morningSnack';
  }

  const key = Object.keys(mealTranslations).find(
    (key) => mealTranslations[key].toLowerCase() === normalizedFrenchMeal
  );

  return key || frenchMeal.toLowerCase();
};

const RecipeSelection = ({
  weekNumber,
  setWeekNumber,
  dayPlans,
  setDayPlans,
  handleDayPlanChange,
  recipes = [],
  userPreferences,
  selectedClient,
  nextStep,
  prevStep,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedDay, setSelectedDay] = useState(null);
  const [selectedMeal, setSelectedMeal] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [categoryFilter, setCategoryFilter] = useState('');

  useEffect(() => {
    if (selectedClient) {
      // console.log('Selected Client:', selectedClient);
    }
    if (userPreferences) {
      // console.log('User Preferences:', userPreferences);
    }
    // console.log('dayPlans updated:', dayPlans);
    // console.log('recipes updated:', recipes);
  }, [selectedClient, userPreferences, dayPlans, recipes]);

  const openDrawer = (dayIndex, meal) => {
    console.log('Opening drawer for meal:', meal);
    setSelectedDay(dayIndex);
    setSelectedMeal(meal);
    setDrawerOpen(true);
  };
  
  const closeDrawer = () => {
    setDrawerOpen(false);
    setSearchTerm('');
    setCategoryFilter('');
  };

  const handleRemoveMeal = (dayIndex, mealKey) => {
    const updatedDayPlans = [...dayPlans];
    if (updatedDayPlans[dayIndex]?.meals[mealKey]) {
      delete updatedDayPlans[dayIndex].meals[mealKey]; // Supprime le repas sélectionné
    }
    setDayPlans(updatedDayPlans);
  };

  const handleDayPlanChangeAndClose = (dayIndex, meal, recipeId) => {
    // Utiliser directement la clé anglaise
    const mealKey = meal; // Assure-toi que `meal` est déjà une clé anglaise
  
    // Mettez à jour uniquement le repas sélectionné
    const updatedDayPlans = [...dayPlans];
    if (!updatedDayPlans[dayIndex].meals) {
      updatedDayPlans[dayIndex].meals = {};
    }
  
    updatedDayPlans[dayIndex].meals[mealKey] = {
      _id: recipeId,
      ...recipes.find((recipe) => recipe._id === recipeId),
    };
  
    setDayPlans(updatedDayPlans);
    closeDrawer();
  };
  
  
  const filterRecipes = (meal, searchTerm, categoryFilter) => {
  
    if (!meal) return [];
    const mealKey = getMealKeyFromFrench(meal);
  
    const effectiveMealKeys =
    mealKey.toLowerCase() === 'morningsnack' ? ['snack'] :
    mealKey === 'lunch' ? ['lunch', 'dinner'] :
    mealKey === 'dinner' ? ['dinner', 'lunch'] :
    mealKey === 'snack' ? ['snack', 'breakfast'] :
    mealKey === 'breakfast' ? ['breakfast', 'snack'] : [mealKey];
  
  
    let filtered = recipes.filter((recipe) =>
      effectiveMealKeys.includes(recipe.mealType?.toLowerCase())
    );
    
  
    filtered = filtered.filter((recipe) =>
      recipe.name?.toLowerCase().includes(searchTerm.toLowerCase() || '')
    );
  
    filtered = filtered.filter((recipe) =>
      categoryFilter ? recipe.category.includes(categoryFilter) : true
    );
  
    filtered = filtered.filter((recipe) => {
      if (filters.intolerances && selectedClient?.intolerances?.length > 0) {
        const hasIntolerance = recipe.intolerances.some((intol) =>
          selectedClient.intolerances.includes(intol)
        );
        return !hasIntolerance;
      }
      return true;
    });
  
    filtered = filtered.filter((recipe) => {
      if (filters.dietaryPreferences && userPreferences?.length > 0) {
        return userPreferences.some((preference) =>
          recipe.category.includes(preference)
        );
      }
      return true;
    });
  
    return filtered;
  };
  
  
  const [filters, setFilters] = useState({
    dietaryPreferences: true,
    intolerances: true,
  });

  const autoFillRecipes = () => {
    const updatedDayPlans = dayPlans.map((dayPlan) => {
      const updatedMeals = { ...dayPlan.meals };
  
      Object.keys(updatedMeals).forEach((mealKey) => {
        const filteredRecipes = filterRecipes(mealKey, '', categoryFilter); // Utilise les clés anglaises ici
        if (filteredRecipes.length > 0) {
          const randomIndex = Math.floor(Math.random() * filteredRecipes.length); // Index aléatoire
          updatedMeals[mealKey] = filteredRecipes[randomIndex]; // Choisir une recette au hasard
        }
      });
  
      return { ...dayPlan, meals: updatedMeals };
    });
  
    setDayPlans(updatedDayPlans);
  };
  

  const handleDayTypeChange = (dayIndex, dayType) => {
    const updatedDayPlans = [...dayPlans];
    updatedDayPlans[dayIndex].dayType = dayType;
    setDayPlans(updatedDayPlans);
  };
  
  const renderMealTitle = (dayIndex, mealKey) => {
    const meal = dayPlans[dayIndex]?.meals[mealKey];
    if (meal && meal._id) {
      // console.log('Rendering meal for day:', dayIndex, 'Meal Key:', mealKey, 'Meal ID:', meal._id);
      const foundRecipe = recipes.find((recipe) => String(recipe._id) === String(meal._id));
      if (foundRecipe) {
        // console.log('Found recipe:', foundRecipe);
        return foundRecipe.name;
      } else {
        // console.warn('Recipe not found for ID:', meal._id);
      }
    }
    return 'None';
  };
  

  return (
    <>

      <UserMin selectedClient={selectedClient} userPreferences={userPreferences} />

      <MagicRecipeBuilder
        filters={filters}
        setFilters={setFilters}
        autoFillRecipes={autoFillRecipes}
      />

<Grid container spacing={2} sx={{ marginBottom: 4 }}>
  {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'].map((day, index) => (
    <Grid item xs={12} sm={6} md={4} key={day}>
      <CalendarCell sx={{ borderRadius: 1 }}>
        {/* Affichage du jour */}
        <Typography sx={{ textAlign: 'left', ml: 1 }} variant="h6">{day}</Typography>

        {/* Sélecteur pour le type de jour */}
        <FormControl fullWidth sx={{ mt: 2, mb: 0 }}>
          <InputLabel id={`day-type-select-label-${index}`}>Type de jour</InputLabel>
          <Select
            labelId={`day-type-select-label-${index}`}
            value={dayPlans[index]?.dayType || 'rest'}
            label="Type de Jour"
            onChange={(e) => handleDayTypeChange(index, e.target.value)}
          >
            <MenuItem value="rest">Repos</MenuItem>
            <MenuItem value="sport">Sport</MenuItem>
          </Select>
        </FormControl>

        {/* Boutons pour les repas */}
        <Box sx={{ marginTop: 1 }}>
          {Object.keys(mealTranslations).map((mealKey) => (
            <Box key={mealKey} sx={{ marginBottom: 1, textAlign: 'left' }}>
              {/* Bouton pour ajouter une recette */}
              <Button
                variant="text"
                sx={{ width: '100%', justifyContent: 'flex-start' }}
                color="inherit"
                startIcon={<AddIcon />}
                onClick={() => openDrawer(index, mealKey)}

              >
                {translateMealToFrench(mealKey)} {/* Affichage en français */}
              </Button>

              {/* Affichage des repas déjà sélectionnés */}
              {dayPlans[index]?.meals[mealKey] && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: '#f5f5f5',
                    borderRadius: 1,
                    padding: '4px 8px',
                    mt: 1,
                  }}
                >
                  <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckCircleIcon sx={{ fontSize: '1.2em', mr: 0.5 }} color="success" />
                    {renderMealTitle(index, mealKey)}
                  </Typography>

                  {/* Bouton pour supprimer le repas */}
                  <Button
                    onClick={() => handleRemoveMeal(index, mealKey)}
                    sx={{ minWidth: 0, padding: 0, color:"black"}}
                  >
                    <RemoveCircleIcon sx={{ fontSize:"1.2em", color:"#a3a3a3" }} />
                  </Button>
                </Box>
              )}

            </Box>
          ))}
        </Box>
      </CalendarCell>
    </Grid>
  ))}
</Grid>

<Drawer anchor="right" open={drawerOpen} onClose={closeDrawer}>
  <Box sx={{ width: 400, padding: 3 }}>
    {/* Affichage du titre dans le Drawer */}
    <Typography variant="h6" sx={{ marginBottom: 2 }}>
      Recette pour {['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'][selectedDay]} - {translateMealToFrench(selectedMeal)}
    </Typography>

    {/* Champ de recherche */}
    <TextField
      label="Rechercher"
      type="text"
      fullWidth
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      sx={{ marginBottom: 2 }}
    />

    {/* Liste des recettes filtrées */}
    <List>
      {filterRecipes(selectedMeal, searchTerm, categoryFilter).map((recipe) => (
        <ListItem key={recipe._id} disablePadding>
          <ListItemButton onClick={() => handleDayPlanChangeAndClose(selectedDay, selectedMeal, recipe._id)}>
            <ListItemText
              primary={
                <Box display="flex" alignItems="center">
                  {recipe.name}
                  <Chip
                    label={recipe.category.map(cat => CATEGORY_TRANSLATIONS[cat] || cat).join(", ")}
                    size="small"
                    sx={{ marginLeft: 1 }}
                  />
                </Box>
              }
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  </Box>
</Drawer>


      <Box
        sx={{
          display: 'flex',
          justifyContent: window.location.pathname.includes('edit') ? 'flex-end' : 'space-between',
        }}
      >
        {!window.location.pathname.includes('edit') && (
          <Button variant="contained" color="secondary" onClick={prevStep} sx={{ marginRight: 2 }}>
            Retour
          </Button>
        )}
        <Button variant="contained" color="primary" onClick={nextStep}>
          Suivant
        </Button>
      </Box>

    </>
  );
};

export default RecipeSelection;
