import React from 'react';
import { Box, Typography, Button, Card, CardContent, Avatar } from '@mui/material';
import { 
  SportsFootball, 
  LocalDining, 
  Psychology, 
  Check as CheckIcon 
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';

function OffersPage() {
  return (
    <>
      <Helmet>
        <title>Yoco - Nos offres</title>
        <meta 
          name="description" 
          content="Découvrez nos offres de coaching en Sport, Nutrition et Mindset, adaptées à vos objectifs de performance et bien-être." 
        />
      </Helmet>
      <Box sx={{ px: { xs: 2, sm: 6 }, pt: 6, pb: 6, maxWidth: '960px', margin: '0 auto' }}>

        {/* Page Title */}
        <Box sx={{ textAlign: 'center', mb: 8 }}>
          <Typography variant="h1" sx={{ mb: 2 }}>
            NOS OFFRES
          </Typography>
          <Typography variant="h5" sx={{ mb: 4 }}>
            Découvrez nos solutions personnalisées en Sport, Nutrition et Mindset pour atteindre vos objectifs.
          </Typography>
        </Box>

        {/* Offers Section */}
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 4, justifyContent: 'center' }}>

          {/* Sport Offer */}
          <Box sx={{ flex: '1 1 calc(45% - 16px)' }}>
            <Card sx={{ height: '100%', border: '1px solid #DDD', p: 3 }} elevation={0}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar sx={{ bgcolor: 'black', mr: 2, width: 40, height: 40 }}>
                    <SportsFootball sx={{ fontSize: 20 }} />
                  </Avatar>
                  <Typography variant="h4" sx={{ fontSize: '1.5em' }}>Sport</Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2, fontWeight:'bold' }}>
                  L’expérience YOCO : une approche fonctionnelle, un impact durable.
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Personal Training : du sur-mesure, totalement adapté à vos plans.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Petits groupes : allier performance et émulation.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Programmations en ligne : accédez à des plans d’entraînement structurés et efficaces, où que vous soyez.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Ebooks : 12 semaines de préparation Hyrox, pour tous niveaux, avec guide d’utilisation.
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  À la recherche du bien-être ou de la performance (Hyrox, CrossFit, course à pied, Triathlon, etc.), nous analysons, comprenons et agissons en toute bienveillance. Nos coachs, issus de corps d’armée d’élite, sont des professionnels diplômés et expérimentés.
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/contact">
                  En savoir plus
                </Button>
              </CardContent>
            </Card>
          </Box>

          {/* Nutrition Offer */}
          <Box sx={{ flex: '1 1 calc(45% - 16px)' }}>
            <Card sx={{ height: '100%', border: '1px solid #DDD', p: 3 }} elevation={0}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar sx={{ bgcolor: 'black', mr: 2, width: 40, height: 40 }}>
                    <LocalDining sx={{ fontSize: 20 }} />
                  </Avatar>
                  <Typography variant="h4" sx={{ fontSize: '1.5em' }}>Nutrition</Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2, fontWeight:'bold' }}>
                  La nutrition 2.0 avec YOCO : une stratégie gagnante.
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Conseils Nutritionnels : connaître les bonnes pratiques pour une vie quotidienne équilibrée et une énergie décuplée.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Accompagnement sur-mesure : adapté à votre plan.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Plateforme automatisée et personnalisée : recettes simples, liste de courses générée automatiquement et calcul précis des aliments.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Suivi simplifié : gardez un œil sur votre progression grâce à votre page personnalisée.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Accessible aux coachs : offrez un service innovant et de qualité à vos élèves pour optimiser leurs résultats.
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Toujours guidé par l’expertise du coach et un renouvellement infini des recettes s’adaptant à votre régime alimentaire, l’accès à la plateforme YOCO est un véritable allié de votre plan.
                </Typography>
                <Typography sx={{ mt:1, mb:2, fontStyle:"italic" }} >*hors traitement ou adaptation médicale.</Typography>
                <Button variant="contained" color="primary" component={Link} to="/contact">
                  En savoir plus
                </Button>
              </CardContent>
            </Card>
          </Box>

          {/* Mindset Offer */}
          <Box sx={{ flex: '1 1 calc(45% - 16px)', mt: 6}}>
            <Card sx={{ height: '100%', border: '1px solid #DDD', p: 3 }} elevation={0}>
              <CardContent>
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Avatar sx={{ bgcolor: 'black', mr: 2, width: 40, height: 40 }}>
                    <Psychology sx={{ fontSize: 20 }} />
                  </Avatar>
                  <Typography variant="h4" sx={{ fontSize: '1.5em' }}>Mindset</Typography>
                </Box>
                <Typography variant="body1" sx={{ mb: 2, fontWeight:'bold' }}>
                  Le Mindset YOCO : la dynamique collective, révélateur de potentiel.
                </Typography>
                <Box sx={{ mb: 2 }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Teambuildings uniques : Olympiades, survie, gestes qui sauvent, Murder Party…
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Développement personnel : révélez votre potentiel caché et renforcez votre confiance.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Séminaires et journées QVT : des thématiques essentielles pour le bien-être au travail.
                    </Typography>
                  </Box>
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <CheckIcon sx={{ fontSize: 16, color: 'black', mr: 0.5 }} />
                    <Typography variant="body1">
                      Formations : partagez l’expertise du terrain pour générer un impact professionnel concret.
                    </Typography>
                  </Box>
                </Box>
                <Typography variant="body1" sx={{ mb: 2 }}>
                  Chaque moment collectif est une opportunité de transformation. Fédérer en s’amusant, c’est possible !
                </Typography>
                <Button variant="contained" color="primary" component={Link} to="/contact">
                  En savoir plus
                </Button>
              </CardContent>
            </Card>
          </Box>

        </Box>

        {/* Call to Action Section */}
        <Box sx={{ backgroundColor: '#000', color: '#fff', width: '100%', py: 5, textAlign: 'center', mt: 12, borderRadius: 2 }}>
          <Typography variant="h2" sx={{ mb: 3 }}>
            Devenez inarrêtable !
          </Typography>
          <Typography variant="body1" sx={{ fontSize: '1.2em', mb: 4, px: { xs: 2, sm: 15 } }}>
            Peu importe vos objectifs, nous avons la solution adaptée. Contactez-nous dès aujourd'hui pour découvrir votre potentiel.
          </Typography>
          <Button variant="contained" component={Link} to="/contact" color="primary" size="large">
            Prendre rendez-vous
          </Button>
        </Box>
      </Box>
    </>
  );
}

export default OffersPage;
