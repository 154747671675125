import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, MenuItem, Box, Typography, Paper, Stack, Alert, CircularProgress, Dialog, DialogTitle, DialogActions, Collapse, Drawer, List, ListItem, IconButton, Card, CardContent, CardActions } from '@mui/material';
import { Search as SearchIcon, Add as AddIcon, ArrowBack as ArrowBackIcon, Delete as DeleteIcon, CheckCircle as CheckCircleIcon } from '@mui/icons-material';
import { useParams } from 'react-router-dom';
import Header from '../../components/Header'; 

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const EditRecipe = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [recipe, setRecipe] = useState(null);
  const [ingredientList, setIngredientList] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedIngredient, setSelectedIngredient] = useState(null);
  const [quantity, setQuantity] = useState('');
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showAlert, setShowAlert] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  const INTOLERANCE_OPTIONS = [
    { value: 'gluten', label: 'Gluten' },
    { value: 'lactose', label: 'Lactose' },
    { value: 'nuts', label: 'Noix' },
    { value: 'shellfish', label: 'Fruits de mer' },
    { value: 'soy', label: 'Soja' },
    { value: 'eggs', label: 'Œufs' },
    { value: 'fish', label: 'Poisson' },
    { value: 'pork', label: 'Porc' },
    { value: 'beef', label: 'Bœuf' },
    { value: 'chicken', label: 'Poulet' },
    { value: 'turkey', label: 'Dinde' },
    { value: 'corn', label: 'Maïs' },
    { value: 'sesame', label: 'Sésame' },
    { value: 'mustard', label: 'Moutarde' },
    { value: 'peanuts', label: 'Arachides' },
    { value: 'citrus', label: 'Agrumes' },
    { value: 'mushrooms', label: 'Champignons' },
    { value: 'legumes', label: 'Légumineuses' },
    { value: 'other', label: 'Autre' },
  ];  

  useEffect(() => {
    // Fetch recipe details
    const fetchRecipe = async () => {
      try {
        const response = await fetch(`${BACKEND_URL}/api/recipes/${id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch recipe details');
        }
        const data = await response.json();
        console.log('Fetched Recipe:', data); // Ajout
        setRecipe(data);
      } catch (error) {
        console.error('Erreur lors de la récupération de la recette:', error);
      }
    };
  
    fetchRecipe();
  
    // Fetch ingredient list from ciqual.json
    fetch('/ciqual.json')
      .then(response => response.json())
      .then(data => {
        console.log('Fetched Ingredient List:', data); // Ajout
        setIngredientList(data);
      });
  }, [id]);  

  useEffect(() => {
    if (recipe && recipe.ingredients.length > 0 && ingredientList.length > 0) {
      console.log('Calculating Macros with Recipe:', recipe); // Ajout
      console.log('Ingredient List:', ingredientList); // Ajout
      calculateMacros();
    }
  // eslint-disable-next-line
  }, [recipe?.ingredients, ingredientList]);
  
  const parseNutrientValue = (value) => {
    if (typeof value === 'string') {
      value = value.replace(',', '.');
      if (value.includes('<') || value === 'traces' || value === '-' || value === '') {
        return 0;
      }
    }
    return parseFloat(value) || 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setRecipe({ ...recipe, [name]: value });
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const toggleDrawer = (open) => () => {
    setDrawerOpen(open);
    setSearchTerm('');
  };

  const handleIngredientSelect = (ingredient) => {
    setSelectedIngredient(ingredient);
    setSearchTerm(ingredient.alim_nom_fr);
    setQuantity('');
  };

  const filteredIngredients = ingredientList.filter(ingredient =>
    ingredient.alim_nom_fr.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const addIngredient = () => {
    if (selectedIngredient && quantity) {
      setRecipe(prevRecipe => ({
        ...prevRecipe,
        ingredients: [
          ...prevRecipe.ingredients,
          { name: selectedIngredient.alim_nom_fr, ciqual_code: selectedIngredient.alim_code, quantity: parseFloat(quantity) }
        ]
      }));
      setSelectedIngredient(null);
      setQuantity('');
      setDrawerOpen(false);
    }
  };

  const deleteIngredient = (indexToDelete) => {
    setRecipe(prevRecipe => ({
      ...prevRecipe,
      ingredients: prevRecipe.ingredients.filter((_, index) => index !== indexToDelete)
    }));
  };

  const calculateMacros = () => {
    let totalCalories = 0;
    let totalCarbs = 0;
    let totalProteins = 0;
    let totalFats = 0;
  
    recipe.ingredients.forEach(ingredient => {
      const ciqualData = ingredientList.find(item => parseInt(item.alim_code) === parseInt(ingredient.ciqual_code));
      console.log('Ingredient:', ingredient);
      console.log('CIQUAL Data Found:', ciqualData);
  
      if (ciqualData) {
        const quantityInGrams = ingredient.quantity;
        totalCalories += (parseNutrientValue(ciqualData["Energie, Règlement UE N° 1169/2011 (kcal/100 g)"]) * quantityInGrams) / 100;
        totalCarbs += (parseNutrientValue(ciqualData["Glucides (g/100 g)"]) * quantityInGrams) / 100;
        totalProteins += (parseNutrientValue(ciqualData["Protéines, N x facteur de Jones (g/100 g)"]) * quantityInGrams) / 100;
        totalFats += (parseNutrientValue(ciqualData["Lipides (g/100 g)"]) * quantityInGrams) / 100;
      } else {
        console.warn(`No CIQUAL data found for ingredient with code: ${ingredient.ciqual_code}`);
      }
    });
  
    console.log('Total Calories:', totalCalories);
    console.log('Total Carbs:', totalCarbs);
    console.log('Total Proteins:', totalProteins);
    console.log('Total Fats:', totalFats);
  
    setRecipe(prevRecipe => ({
      ...prevRecipe,
      macros: {
        calories: totalCalories,
        carbohydrates: totalCarbs,
        proteins: totalProteins,
        fats: totalFats
      }
    }));
  };
  
  const handleSubmit = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/api/recipes/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify(recipe),
      });
  
      if (!response.ok) {
        throw new Error('Failed to update recipe');
      }
  
      setSuccessModalOpen(true); // Ouvre la popin de succès
    } catch (error) {
      console.error('Erreur lors de la mise à jour de la recette:', error);
      setErrorMessage(`Erreur lors de la mise à jour de la recette : ${error.message}`);
      setShowAlert(true);
    }
  };
  

  if (!recipe) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );  }


  return (
    <>

    <Header /> 

    <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>

      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Button color='black' variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate('/recipes')}>
          Retour
        </Button>
      </Stack>

      <Paper elevation={0} sx={{ p: { xs: 2, sm: 4 }, border:'1px solid #DDD' }}> 

      <Typography  sx={{ mb:4 }} variant="h4">Editer la recette</Typography>

      <TextField
        label="Nom de la recette"
        name="name"
        value={recipe.name}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Type de repas"
        name="mealType"
        value={recipe.mealType}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
        select
      >
        <MenuItem value="breakfast">Petit-déjeuner</MenuItem>
        <MenuItem value="lunch">Déjeuner</MenuItem>
        <MenuItem value="dinner">Dîner</MenuItem>
        <MenuItem value="snack">Encas</MenuItem>
      </TextField>
      <TextField
        label="Catégorie"
        name="category"
        value={recipe.category}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
        select
      >
        <MenuItem value="vegetarian">Vegetarien</MenuItem>
        <MenuItem value="vegan">Vegan</MenuItem>
        <MenuItem value="gluten-free">Sans gluten</MenuItem>
        <MenuItem value="dairy-free">Sans produits laitiers</MenuItem>
        <MenuItem value="paleo">Paléo</MenuItem>
        <MenuItem value="keto">Kéto</MenuItem>
        <MenuItem value="other">Autre</MenuItem>
      </TextField>
      <TextField
        label="Temps de préparation (min)"
        name="preparationTime"
        value={recipe.preparationTime}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />
      <TextField
        label="Temps de cuisson (min)"
        name="cookingTime"
        value={recipe.cookingTime}
        onChange={handleInputChange}
        fullWidth
        sx={{ mb: 2 }}
      />

      {/* Bouton pour ouvrir le drawer de sélection des ingrédients */}
      <Button variant="contained" color="secondary"  sx={{ width: '100%' }} onClick={toggleDrawer(true)} startIcon={<AddIcon />}>
        Ajouter un ingrédient
      </Button>

      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <Box sx={{ width: 300, p: 2 }}>
        <Typography variant="h5">Ingrédients</Typography>
        <TextField
            label="Rechercher"
            value={searchTerm}
            onChange={handleSearchChange}
            fullWidth
            sx={{ mb: 2, mt:2 }}
            InputProps={{
              endAdornment: <SearchIcon />
            }}
          />
          <List>
            {filteredIngredients.map(ingredient => (
              <ListItem
                button
                key={ingredient.alim_code}
                onClick={() => handleIngredientSelect(ingredient)}
                selected={selectedIngredient?.alim_code === ingredient.alim_code}
              >
                {ingredient.alim_nom_fr}
                {selectedIngredient?.alim_code === ingredient.alim_code && (
                  <CheckCircleIcon sx={{ ml: 2, color: 'green' }} />
                )}
              </ListItem>
            ))}
          </List>
          <TextField
            label="Quantité (g)"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            fullWidth
            sx={{ mt: 2 }}
            disabled={!selectedIngredient} // Désactiver tant qu'aucun ingrédient n'est sélectionné
          />
          <Button
            variant="contained"
            onClick={addIngredient}
            sx={{ mt: 2, width:'100%', boxShadow:'none' }}
            disabled={!selectedIngredient || !quantity} // Désactiver tant qu'aucun ingrédient ou quantité n'est fourni
          >
            Ajouter
          </Button>
        </Box>
      </Drawer>

      {/* Liste des ingrédients ajoutés, affichés sous forme de cartes */}
      <Box sx={{ mt: 4, display: 'flex', flexWrap: 'wrap', gap: 2 }}>
        {recipe.ingredients.map((ingredient, index) => (
          <Card key={index} sx={{ width: 250, border:'1px solid #DDD' }} elevation="0" >
            <CardContent sx={{ p:0, px:2, pt:2 }} >
              <Typography sx={{ mb:2, lineHeight:'1.2em' }} variant="h6">{ingredient.name}</Typography>
              <Typography variant="body2">Quantité : {ingredient.quantity}g</Typography>
              <Typography variant="body2">CIQUAL Code : {ingredient.ciqual_code}</Typography>
            </CardContent>
            <CardActions>
              <IconButton aria-label="delete" onClick={() => deleteIngredient(index)}>
                <DeleteIcon />
              </IconButton>
            </CardActions>
          </Card>
        ))}
      </Box>

      {/* Affichage des macros calculés */}
      {recipe.macros?.calories >= 0 && (
        <Box sx={{ mt: 2 }}>
            <Typography variant="h6">Calories : {recipe.macros.calories.toFixed(2)} kcal</Typography>
            <Typography variant="body1">Glucides : {recipe.macros.carbohydrates.toFixed(2)} g</Typography>
            <Typography variant="body1">Protéines : {recipe.macros.proteins.toFixed(2)} g</Typography>
            <Typography variant="body1">Lipides : {recipe.macros.fats.toFixed(2)} g</Typography>
        </Box>
      )}

      {/* Bouton pour calculer les macros */}
      <Button onClick={calculateMacros} variant="contained" color="secondary" sx={{ mt: 4, width: '100%', boxShadow: 'none' }}>
        Calculer les macros
      </Button>

      <TextField
        label="Instructions"
        name="instructions"
        value={recipe.instructions}
        onChange={handleInputChange}
        fullWidth
        multiline
        rows={4}
        sx={{ mt: 4, mb:2 }}
      />

      <TextField
        select
        label="Intolérances"
        name="intolerances"
        value={recipe?.intolerances || []} // Défaut à un tableau vide si les intolérances ne sont pas encore chargées
        onChange={(e) =>
          setRecipe((prevRecipe) => ({
            ...prevRecipe,
            intolerances: e.target.value,
          }))
        }
        fullWidth
        sx={{ mb: 2 }}
        SelectProps={{
          multiple: true, // Permet la sélection multiple
          renderValue: (selected) =>
            selected
              .map(
                (intol) =>
                  INTOLERANCE_OPTIONS.find((opt) => opt.value === intol)?.label || intol
              )
              .join(', '),
        }}
      >
        {INTOLERANCE_OPTIONS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>


      {/* Bouton pour sauvegarder la recette */}
      <Button onClick={handleSubmit} variant="contained" color="primary" sx={{ mt: 2, width: '100%', boxShadow: 'none' }}>
        Sauvegarder
      </Button>

      <Collapse in={showAlert}>
        <Alert
          severity="error"
          onClose={() => setShowAlert(false)}
          sx={{ mb: 2, mt:2 }}
        >
          {errorMessage}
        </Alert>
      </Collapse>

    <Collapse in={!!successMessage}>
      <Alert
        severity="success"
        onClose={() => setSuccessMessage('')}
        sx={{ mb: 2, mt:2 }}
      >
        {successMessage}
      </Alert>
    </Collapse>

      </Paper>

    </Box>

    <Dialog open={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
      <DialogTitle>Recette mise à jour avec succès</DialogTitle>
      <DialogActions>
        <Button onClick={() => navigate('/recipes')} color="primary">
          OK
        </Button>
      </DialogActions>
    </Dialog>

    </>
  );
};

export default EditRecipe;
