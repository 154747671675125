import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Alert,
  Typography,
  Paper,
  Stack,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogActions,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Header from '../../components/Header';

const EditUser = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  // eslint-disable-next-line
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [formValues, setFormValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    age: '',
    weight: '',
    height: '',
    gender: '', // Champ gender ajouté
    activityLevel: '',
    goals: '',
    dietaryPreferences: [],
    intolerances: [],
    medicalConditions: '',
    targetMacros: {
      carbohydrates: '',
      proteins: '',
      fats: '',
    },
    role: '',
    isVerified: false,
    active: true,
  });

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/${id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch user details');
        }

        const data = await response.json();
        setUser(data);
        setFormValues({
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          phone: data.phone || '',
          age: data.age || '',
          weight: data.weight || '',
          height: data.height || '',
          gender: data.gender || '', // Récupération du genre
          activityLevel: data.activityLevel || '',
          goals: data.goals || '',
          dietaryPreferences: data.dietaryPreferences || [],
          intolerances: data.intolerances || [],
          medicalConditions: data.medicalConditions.join(', ') || '',
          targetMacros: {
            carbohydrates: data.targetMacros?.carbohydrates || '',
            proteins: data.targetMacros?.proteins || '',
            fats: data.targetMacros?.fats || '',
          },
          role: data.role || 'user',
          isVerified: data.isVerified || false,
          active: data.active,
        });
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [id]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues({ ...formValues, [name]: checked });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/${id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
        },
        body: JSON.stringify({
          ...formValues,
          medicalConditions: formValues.medicalConditions.split(',').map((cond) => cond.trim()),
          intolerances: formValues.intolerances,
          dietaryPreferences: formValues.dietaryPreferences,
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update user');
      }

      setSuccessModalOpen(true); // Ouvre la popin de succès
    } catch (error) {
      setError(error.message);
    }
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  return (
    <>
      <Header />

      <Box
        sx={{
          px: { xs: 2, sm: 6 },
          pt: 3,
          pb: 6,
          maxWidth: '880px',
          width: { xs: '-webkit-fill-available', md: '100%' },
          margin: '0 auto',
        }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <Button color="black" variant="outlined" startIcon={<ArrowBackIcon />} onClick={() => navigate('/users')}>
            Retour
          </Button>
        </Stack>
        <Paper elevation={0} sx={{ p: { xs: 2, sm: 4 }, border: '1px solid #DDD' }}>
          <Typography variant="h6" sx={{ mb: 2 }}>
            Données personnelles
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              label="Prénom"
              name="firstName"
              value={formValues.firstName}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Nom"
              name="lastName"
              value={formValues.lastName}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Email"
              name="email"
              value={formValues.email}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Téléphone"
              name="phone"
              value={formValues.phone}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />

            {/* Champ pour le genre */}
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="gender-label">Genre</InputLabel>
              <Select
                labelId="gender-label"
                name="gender"
                value={formValues.gender}
                label="Genre"
                onChange={handleChange}
              >
                <MenuItem value="male">Masculin</MenuItem>
                <MenuItem value="female">Féminin</MenuItem>
                <MenuItem value="other">Autre</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
              Profil physique
            </Typography>
            <TextField
              label="Âge"
              name="age"
              type="number"
              value={formValues.age}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Poids (kg)"
              name="weight"
              type="number"
              value={formValues.weight}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Taille (cm)"
              name="height"
              type="number"
              value={formValues.height}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="activityLevel-label">Niveau d'activité</InputLabel>
              <Select
                labelId="activityLevel-label"
                name="activityLevel"
                value={formValues.activityLevel}
                label="Niveau d'activité"
                onChange={handleChange}
              >
                <MenuItem value="sedentary">Sédentaire</MenuItem>
                <MenuItem value="moderate">Modéré</MenuItem>
                <MenuItem value="active">Actif</MenuItem>
              </Select>
            </FormControl>

            <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
              Option de coaching
            </Typography>
            <TextField
              label="Objectifs"
              name="goals"
              value={formValues.goals}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Conditions médicales"
              name="medicalConditions"
              value={formValues.medicalConditions}
              onChange={handleChange}
              fullWidth
              sx={{ mb: 2 }}
            />
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="intolerances-label">Intolérances</InputLabel>
              <Select
                labelId="intolerances-label"
                name="intolerances"
                multiple
                value={formValues.intolerances}
                onChange={(e) =>
                  setFormValues({ ...formValues, intolerances: e.target.value })
                }
              >
                <MenuItem value="gluten">Gluten</MenuItem>
                <MenuItem value="lactose">Lactose</MenuItem>
                <MenuItem value="nuts">Noix</MenuItem>
                <MenuItem value="shellfish">Fruits de mer</MenuItem>
                <MenuItem value="soy">Soja</MenuItem>
                <MenuItem value="eggs">Œufs</MenuItem>
                <MenuItem value="fish">Poisson</MenuItem>
                <MenuItem value="pork">Porc</MenuItem>
                <MenuItem value="beef">Bœuf</MenuItem>
                <MenuItem value="chicken">Poulet</MenuItem>
                <MenuItem value="turkey">Dinde</MenuItem>
                <MenuItem value="corn">Maïs</MenuItem>
                <MenuItem value="sesame">Sésame</MenuItem>
                <MenuItem value="mustard">Moutarde</MenuItem>
                <MenuItem value="peanuts">Arachides</MenuItem>
                <MenuItem value="citrus">Agrumes</MenuItem>
                <MenuItem value="mushrooms">Champignons</MenuItem>
                <MenuItem value="legumes">Légumineuses</MenuItem>
                <MenuItem value="other">Autre</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="dietaryPreferences-label">Préférences alimentaires</InputLabel>
              <Select
                labelId="dietaryPreferences-label"
                name="dietaryPreferences"
                multiple
                value={formValues.dietaryPreferences}
                onChange={(e) =>
                  setFormValues({ ...formValues, dietaryPreferences: e.target.value })
                }
              >
                <MenuItem value="vegetarian">Végétarien</MenuItem>
                <MenuItem value="vegan">Vegan</MenuItem>
                <MenuItem value="gluten-free">Sans gluten</MenuItem>
                <MenuItem value="dairy-free">Sans produits laitiers</MenuItem>
                <MenuItem value="paleo">Paléo</MenuItem>
                <MenuItem value="keto">Kéto</MenuItem>
                <MenuItem value="other">Autre</MenuItem>
              </Select>
            </FormControl>
            <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
              Objectifs nutritionnels
            </Typography>
            <TextField
              label="Glucides (%)"
              name="targetMacros.carbohydrates"
              type="number"
              value={formValues.targetMacros.carbohydrates}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  targetMacros: { ...formValues.targetMacros, carbohydrates: e.target.value },
                })
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Protéines (%)"
              name="targetMacros.proteins"
              type="number"
              value={formValues.targetMacros.proteins}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  targetMacros: { ...formValues.targetMacros, proteins: e.target.value },
                })
              }
              fullWidth
              sx={{ mb: 2 }}
            />
            <TextField
              label="Lipides (%)"
              name="targetMacros.fats"
              type="number"
              value={formValues.targetMacros.fats}
              onChange={(e) =>
                setFormValues({
                  ...formValues,
                  targetMacros: { ...formValues.targetMacros, fats: e.target.value },
                })
              }
              fullWidth
              sx={{ mb: 2 }}
            />

            <Typography variant="h6" sx={{ mb: 2, mt: 4 }}>
              Autres
            </Typography>
            <FormControlLabel
              control={
                <Checkbox checked={formValues.active} onChange={handleCheckboxChange} name="active" />
              }
              label="Actif"
              sx={{ mb: 2 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={formValues.isVerified}
                  onChange={handleCheckboxChange}
                  name="isVerified"
                />
              }
              label="Vérifié"
              sx={{ mb: 2 }}
            />

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="role-label">Rôle</InputLabel>
              <Select
                labelId="role-label"
                name="role"
                value={formValues.role}
                label="Rôle"
                onChange={handleChange}
              >
                <MenuItem value="user">Utilisateur</MenuItem>
                <MenuItem value="coach">Coach</MenuItem>
                <MenuItem value="admin">Administrateur</MenuItem>
              </Select>
            </FormControl>

            {/* Champs pour l'abonnement, affichés uniquement si le rôle est coach */}
            {formValues.role === 'coach' && (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={formValues.isSubscribed}
                      onChange={handleCheckboxChange}
                      name="isSubscribed"
                    />
                  }
                  label="Abonnement actif"
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Date de début d'abonnement"
                  name="subscriptionStartDate"
                  type="date"
                  value={formValues.subscriptionStartDate || ''}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Date de fin d'abonnement"
                  name="subscriptionEndDate"
                  type="date"
                  value={formValues.subscriptionEndDate || ''}
                  onChange={handleChange}
                  InputLabelProps={{ shrink: true }}
                  fullWidth
                  sx={{ mb: 2 }}
                />
                <TextField
                  label="Produit d'abonnement (Stripe)"
                  name="subscriptionProduct"
                  value={formValues.subscriptionProduct || ''}
                  onChange={handleChange}
                  fullWidth
                  sx={{ mb: 2 }}
                />
              </>
            )}

            <Button type="submit" variant="contained" color="primary" sx={{ mt: 2, width: '100%', boxShadow: 'none' }}>
              Sauvegarder
            </Button>
          </form>
        </Paper>
      </Box>

      <Dialog open={successModalOpen} onClose={() => setSuccessModalOpen(false)}>
        <DialogTitle>Utilisateur mis à jour avec succès</DialogTitle>
        <DialogActions>
          <Button onClick={() => navigate('/users')} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EditUser;
