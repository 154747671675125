import React, { useEffect, useState, useContext  } from 'react'; 
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  CircularProgress,
  Drawer,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Header from '../../components/Header';
import CoachHeader from '../../components/coach/CoachHeader'; 
import RecipeView from '../../components/recipes/RecipeView';
import { getRecipeEmoji } from '../../utils/getRecipeEmoji';
import { AuthContext } from '../../context/AuthContext'; 

const DIETARY_TRANSLATIONS = {
  vegetarian: 'Végétarien',
  vegan: 'Vegan',
  'gluten-free': 'Sans gluten',
  'dairy-free': 'Sans produits laitiers',
  paleo: 'Paléo',
  keto: 'Kéto',
  other: 'Autre',
};

const mealTranslations = {
  breakfast: 'Petit-déjeuner',
  lunch: 'Déjeuner',
  snack: 'Encas',
  dinner: 'Dîner',
};

const RecipeList = () => {
  const navigate = useNavigate();
  const [recipes, setRecipes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRecipe, setSelectedRecipe] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [recipeToDelete, setRecipeToDelete] = useState(null);
  const [filterMealType, setFilterMealType] = useState('all');
  const [filterDietary, setFilterDietary] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const { user } = useContext(AuthContext); 

  useEffect(() => {
    const fetchRecipes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/recipes`);
        if (!response.ok) {
          throw new Error('Échec de la récupération des recettes.');
        }
        const data = await response.json();
        setRecipes(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRecipes();
  }, []);

  const handleViewRecipe = (recipe) => {
    setSelectedRecipe(recipe);
    setDrawerOpen(true);
  };

  const handleEditRecipe = (recipe) => {
    navigate(`/recipes/edit/${recipe._id}`);
  };

  const handleDeleteRecipeClick = (recipe) => {
    setRecipeToDelete(recipe);
    setDeleteDialogOpen(true);
  };

  const handleDeleteRecipeConfirm = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/recipes/${recipeToDelete._id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      });

      if (!response.ok) {
        throw new Error('Échec de la suppression de la recette.');
      }

      setRecipes(prevRecipes => prevRecipes.filter(recipe => recipe._id !== recipeToDelete._id));
      setDeleteDialogOpen(false);
      setRecipeToDelete(null);
    } catch (error) {
      console.error('Erreur lors de la suppression de la recette :', error);
    }
  };

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setRecipeToDelete(null);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
    setSelectedRecipe(null);
  };

  const handleMealTypeFilterChange = (event) => {
    setFilterMealType(event.target.value);
  };

  const handleDietaryFilterChange = (event) => {
    setFilterDietary(event.target.value);
  };

  const filteredRecipes = recipes.filter(recipe => {
    const matchesMealType = filterMealType === 'all' || recipe.mealType === filterMealType;
    const matchesDietary = filterDietary === 'all' || recipe.category.includes(filterDietary);
    const matchesSearch = recipe.name.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesMealType && matchesDietary && matchesSearch;
  });

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography variant="h6" color="error">Erreur : {error}</Typography>;
  }

  return (
    <>
      {/* Affichage du header basé sur le rôle */}
      {user?.role === 'admin' ? <Header /> : <CoachHeader />}
      <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2.5 }}>
          <Typography variant="h3">Recettes</Typography>
            {user?.role === 'admin' && (
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => navigate('/recipes/create')}
              >
                Ajouter
              </Button>
            )}
        </Box>

        {/* Filtres de recherche et tri compactés */}
        <Box
  sx={{
    display: 'flex',
    alignItems: 'center',
    mb: 3,
    flexDirection: { xs: 'column', sm: 'row' },
    gap: { xs: 2, sm: 3 },
  }}
>
  <Box
    sx={{
      display: 'flex',
      width: { xs: '100%', sm: 'auto' },
      gap: 2,
      flexDirection: 'row',
    }}
  >
    <FormControl sx={{ flex: 2 }}>
      <InputLabel id="meal-type-filter-label">Repas</InputLabel>
      <Select
        labelId="meal-type-filter-label"
        id="meal-type-filter"
        value={filterMealType}
        label="Type de repas"
        onChange={handleMealTypeFilterChange}
      >
        <MenuItem value="all">Tous</MenuItem>
        <MenuItem value="breakfast">Petit-déjeuner</MenuItem>
        <MenuItem value="lunch">Déjeuner</MenuItem>
        <MenuItem value="snack">Encas</MenuItem>
        <MenuItem value="dinner">Dîner</MenuItem>
      </Select>
    </FormControl>

    <FormControl sx={{ flex: 2 }}>
      <InputLabel id="dietary-filter-label">Régime</InputLabel>
      <Select
        labelId="dietary-filter-label"
        id="dietary-filter"
        value={filterDietary}
        label="Régime alimentaire"
        onChange={handleDietaryFilterChange}
      >
        <MenuItem value="all">Tous</MenuItem>
        <MenuItem value="vegetarian">Végétarien</MenuItem>
        <MenuItem value="vegan">Vegan</MenuItem>
        <MenuItem value="gluten-free">Sans gluten</MenuItem>
        <MenuItem value="dairy-free">Sans produits laitiers</MenuItem>
        <MenuItem value="paleo">Paléo</MenuItem>
        <MenuItem value="keto">Kéto</MenuItem>
      </Select>
    </FormControl>
  </Box>

  <TextField
    label="Rechercher..."
    variant="outlined"
    value={searchTerm}
    onChange={(e) => setSearchTerm(e.target.value)}
    sx={{ mt: { xs: 0, sm: 0 }, width: { xs: '100%', sm: '100%' } }}
  />
</Box>

        <Grid container spacing={3}>
          {filteredRecipes.map((recipe) => (
            <Grid item xs={12} sm={6} md={4} key={recipe._id}>
              <Card elevation={0} sx={{ border: '1px solid #DDD'}}>
                <CardContent sx={{ p:0, paddingBottom:'0 !important' }} >

                  {/* Emoji de la recette */}
                  <Box
                    onClick={() => handleViewRecipe(recipe)}
                    sx={{
                      width: '100%',
                      height: 100,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      backgroundColor: '#EEE',
                      mb: 2,
                      fontSize: '3rem',
                      cursor:"pointer", 
                    }}
                  >
                    {getRecipeEmoji(recipe.name)}
                  </Box>

                  <Box sx={{ px:2, pb:2 }}>

                    <Box sx={{ cursor:"pointer" }} onClick={() => handleViewRecipe(recipe)}>
                      <Typography variant="h5" sx={{ mb: 2 }}>{recipe.name}</Typography>
                      <Chip sx={{ mr: 0.5 }} variant="body2" label={mealTranslations[recipe.mealType] || recipe.mealType} />
                      <Chip variant="body2" label={recipe.category.map(cat => DIETARY_TRANSLATIONS[cat] || cat).join(', ')} />
                    </Box>

                      <Box sx={{ mt: 2, display: 'flex', justifyContent: 'space-between' }}>
                          {user?.role === 'admin' && (
                            <>
                              <IconButton onClick={() => handleViewRecipe(recipe)}>
                                <VisibilityIcon />
                              </IconButton>
                              <IconButton onClick={() => handleEditRecipe(recipe)}>
                                <EditIcon />
                              </IconButton>
                              <IconButton onClick={() => handleDeleteRecipeClick(recipe)}>
                                <DeleteIcon />
                              </IconButton>
                            </>
                          )}
                      </Box>

                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        {/* Drawer pour afficher les détails de la recette */}
        <Drawer PaperProps={{ sx: { width: "100%" }, }} anchor="right" open={drawerOpen} onClose={handleCloseDrawer}>
          {selectedRecipe && <RecipeView recipe={selectedRecipe} onClose={handleCloseDrawer} />}
        </Drawer>

        {/* Boîte de dialogue pour confirmer la suppression */}
        <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
          <DialogTitle>Confirmer la Suppression</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir supprimer la recette <strong>{recipeToDelete?.name}</strong> ? Cette action est irréversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary">
              Annuler
            </Button>
            <Button onClick={handleDeleteRecipeConfirm} color="error">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default RecipeList;
