import React, { useState, useEffect, useContext } from 'react';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import GeneralInfo from '../../components/dashboard/GeneralInfo';
import Recommendations from '../../components/dashboard/Recommendations';
import DayPlans from '../../components/dashboard/DayPlans';
import ShoppingList from '../../components/dashboard/ShoppingList';
import { createShoppingList } from '../../utils/createShoppingList';
import { adjustRecipeMacros } from '../../utils/adjustRecipeMacros';
import { AuthContext } from '../../context/AuthContext';
import { Helmet } from 'react-helmet-async';

const UserPlanView = () => {
  const { user, authToken } = useContext(AuthContext);
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [shoppingList, setShoppingList] = useState([]);

  const adjustDayPlansRecipes = async (dayPlans, targetMacros) => {
    const adjustedRecipes = [];
    for (const dayPlan of dayPlans) {
      // Passer le dayType (sport ou rest) à la fonction d'ajustement des recettes
      for (const meal of Object.values(dayPlan.meals)) {
        if (meal) {
          const adjustedRecipe = await adjustRecipeMacros(meal, targetMacros, dayPlan.dayType);  // Ajout de dayPlan.dayType
          adjustedRecipes.push(adjustedRecipe);
        }
      }
    }
    return adjustedRecipes;
  };

  useEffect(() => {
    const fetchUserPlan = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/weekplans`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des données du programme.');
        }

        const data = await response.json();

        if (!data.dayPlans || !data.user?.targetMacros) {
          throw new Error('Recette ou macros cibles manquantes.');
        }

        setPlan(data);

        // Vérification du localStorage pour éviter un recalcul si targetMacros, dayTypes ou recipeIds n'ont pas changé
        const storedMacros = JSON.parse(localStorage.getItem('targetMacros'));
        const storedDayTypes = JSON.parse(localStorage.getItem('dayTypes'));
        const storedRecipeIds = JSON.parse(localStorage.getItem('recipeIds'));

        // Récupérer les IDs des recettes actuelles
        const currentRecipeIds = data.dayPlans.map(dayPlan =>
          Object.values(dayPlan.meals).map(meal => meal ? meal._id : null)
        ).flat();

        // Vérification de la correspondance de targetMacros, dayTypes et recipeIds
        if (storedMacros && JSON.stringify(storedMacros) === JSON.stringify(data.user.targetMacros) &&
            storedDayTypes && JSON.stringify(storedDayTypes) === JSON.stringify(data.dayPlans.map(day => day.dayType)) &&
            storedRecipeIds && JSON.stringify(storedRecipeIds) === JSON.stringify(currentRecipeIds)) {
          const storedRecipes = JSON.parse(localStorage.getItem('adjustedRecipes'));
          setShoppingList(createShoppingList(storedRecipes));
          setLoading(false);
        } else {
          // Si targetMacros, dayTypes ou recipeIds ont changé, on effectue le calcul et on stocke
          const adjustedRecipes = await adjustDayPlansRecipes(data.dayPlans, data.user.targetMacros);
          const generatedList = createShoppingList(adjustedRecipes);
          setShoppingList(generatedList);

          // Stockage dans le localStorage
          localStorage.setItem('adjustedRecipes', JSON.stringify(adjustedRecipes));
          localStorage.setItem('targetMacros', JSON.stringify(data.user.targetMacros));

          // Stockage des dayTypes dans le localStorage
          const dayTypes = data.dayPlans.map(day => day.dayType);
          localStorage.setItem('dayTypes', JSON.stringify(dayTypes));  // Stocke tous les dayTypes des jours de la semaine

          // Stockage des IDs des recettes dans le localStorage
          const recipeIds = data.dayPlans.map(dayPlan =>
            Object.values(dayPlan.meals).map(meal => meal ? meal._id : null)
          ).flat();
          localStorage.setItem('recipeIds', JSON.stringify(recipeIds));  // Stocke les IDs des recettes

          setLoading(false);
        }
      } catch (err) {
        console.error('Erreur lors de la récupération des données :', err.message);
        setError(err.message);
        setLoading(false);
      }
    };

    if (user) {
      fetchUserPlan();
    }
  }, [user, authToken]);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error || !plan) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '75vh',
          textAlign: 'center',
        }}
      >
        <Typography variant="h2" sx={{ mb: 2 }}>
        Bonjour {user?.firstName || ''}
        </Typography>
        <Typography variant="subtitle1" sx={{ mb: 2 }}>
          Vous n'avez pas de programme actif.
        </Typography>
        <Box sx={{ display: 'flex', gap: 2 }}>
          <Button
            variant="contained"
            color="primary"
            href="/contact"
            sx={{ padding: '10px 20px'}}
          >
            J'en veux un
          </Button>
          <Button
            variant="text"
            color="black"
            href="/contact"
          >
            Obtenir de l'aide
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>Yoco - Dashboard</title>
        <meta name="keywords" content="coaching, sport, nutrition, bien-être, performance" />
      </Helmet>
      <Box
        sx={{
          px: { xs: 2, sm: 6 },
          pt: 3,
          pb: 6,
          maxWidth: '880px',
          width: { xs: '-webkit-fill-available', md: '100%' },
          margin: '0 auto',
        }}
      >
        <GeneralInfo
          user={plan.user}
          dietaryPreferences={plan.user?.dietaryPreferences}
          goals={plan.user?.goals}
          allergies={plan.user?.allergies}
          summary={plan.summary}
          targetMacros={plan.user?.targetMacros}
          planId={plan._id}
        />
        <Recommendations recommendations={plan.recommendations} />
        <DayPlans dayPlans={plan.dayPlans} targetMacros={plan.user?.targetMacros} />
        <ShoppingList shoppingList={shoppingList} />
      </Box>
    </>
  );
};

export default UserPlanView;
