import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import EntityView from '../../../components/entity/EntityView';
import Header from '../../../components/Header';
import { translateIntolerances, translateActivityLevel, translateDietaryPreferences } from '../../../utils/translate';
import { Box, CircularProgress } from '@mui/material';

const ClientView = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fonction pour récupérer les données client
  useEffect(() => {
    const fetchClient = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/clients/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });

        if (!response.ok) {
          throw new Error('Erreur lors de la récupération des détails du client.');
        }

        const data = await response.json();
        setClient(data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchClient();
  }, [id]);

  // Calcul des pourcentages des macros nutritionnels
  const calculateMacroPercentages = (macros) => {
    const total = (macros.carbohydrates || 0) + (macros.proteins || 0) + (macros.fats || 0);
    return {
      carbohydrates: total ? ((macros.carbohydrates || 0) / total) * 100 : 0,
      proteins: total ? ((macros.proteins || 0) / total) * 100 : 0,
      fats: total ? ((macros.fats || 0) / total) * 100 : 0,
    };
  };

  // Sections à afficher dans EntityView
  const renderSections = () => {
    if (!client) return [];

    const macroPercentages = calculateMacroPercentages(client.targetMacros || {});

    // Transformation du genre pour un affichage en français
    const genderLabel = client.gender
      ? client.gender === 'male'
        ? 'Masculin'
        : client.gender === 'female'
          ? 'Féminin'
          : 'Autre'
      : 'Non renseigné';

    return [
      {
        title: 'Informations personnelles',
        fields: [
          { label: 'Nom', value: `${client.firstName} ${client.lastName}` },
          { label: 'Email', value: client.email },
          { label: 'Téléphone', value: client.phone || 'Non renseigné' },
          { label: 'Genre', value: genderLabel },
        ],
      },
      {
        title: 'Données physiques',
        fields: [
          { label: 'Âge', value: client.age || 'Non renseigné' },
          { label: 'Poids', value: client.weight ? `${client.weight} kg` : 'Non renseigné' },
          { label: 'Taille', value: client.height ? `${client.height} cm` : 'Non renseigné' },
        ],
      },
      {
        title: 'Objectifs et santé',
        fields: [
          { label: 'Niveau d\'activité', value: client.activityLevel ? translateActivityLevel(client.activityLevel) : 'Non renseigné' },
          { label: 'Objectifs', value: client.goals || 'Non renseigné' },
          { label: 'Conditions médicales', value: client.medicalConditions?.length ? client.medicalConditions.join(', ') : 'Aucune' },
          { label: 'Intolérances', value: client.intolerances?.length ? translateIntolerances(client.intolerances).join(', ') : 'Aucune' },
        ],
      },
      {
        title: 'Régime alimentaire',
        fields: [
          { label: 'Régime', value: client.dietaryPreferences?.length ? translateDietaryPreferences(client.dietaryPreferences).join(', ') : 'Aucun' },
        ],
      },
      {
        title: 'Objectifs nutritionnels',
        fields: [
          { label: 'Glucides', value: `${macroPercentages.carbohydrates.toFixed(2)}%` },
          { label: 'Protéines', value: `${macroPercentages.proteins.toFixed(2)}%` },
          { label: 'Lipides', value: `${macroPercentages.fats.toFixed(2)}%` },
        ],
      },
    ];
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <div>Erreur : {error}</div>
      </Box>
    );
  }

  return (
    <>
      <Header />
      <Box>
        <EntityView
          title="Détails du client"
          sections={renderSections()}
          navigateBack={() => navigate('/coach/clients')}
          onEdit={() => navigate(`/coach/clients/edit/${id}`)}
        />
      </Box>
    </>
  );
};

export default ClientView;
