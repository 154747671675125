import React from 'react';
import { Box, Typography, Paper, Divider, Stack, Button } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';

const EntityView = ({ 
  title, 
  sections, 
  navigateBack, 
  onEdit 
}) => {
  return (
    <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', margin: '0 auto' }}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
        <Button variant="outlined" color="black" startIcon={<ArrowBackIcon />} onClick={navigateBack}>
          Retour
        </Button>
        {onEdit && (
          <Button variant="contained" startIcon={<EditIcon />} onClick={onEdit}>
            Éditer
          </Button>
        )}
      </Stack>
      <Paper elevation={0} sx={{ p: { xs: 2, sm: 4 }, border: '1px solid #DDD' }}>
        <Typography variant="h3" sx={{ mb: 4 }}>{title}</Typography>
        {sections.map((section, sectionIndex) => (
          <Box key={sectionIndex} sx={{ mb: 4 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>{section.title}</Typography>
            <Divider sx={{ mb: 2 }} />
            {section.fields.map((field, fieldIndex) => (
              <Box key={fieldIndex} sx={{ mb: 2 }}>
                <Typography>
                  <strong>{field.label} :</strong> {field.value || 'Non renseigné'}
                </Typography>
              </Box>
            ))}
          </Box>
        ))}
      </Paper>
    </Box>
  );
};

export default EntityView;
