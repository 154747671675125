import React from 'react';
import { AppBar, Toolbar, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Header = () => {
  const navigate = useNavigate();

  return (
    <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: 'none', borderBottom: '1px solid #DDD' }}>
      <Toolbar sx={{ margin:'0 auto' }} >
        <Button color="inherit" sx={{ color: 'black', fontWeight:"bold" }} onClick={() => navigate('/coach/clients')}>
          Clients
        </Button>
        <Button color="inherit" sx={{ color: 'black', fontWeight:"bold",  mx:1 }} onClick={() => navigate('/coach/recipes')}>
          Recettes
        </Button>
        <Button color="inherit" sx={{ color: 'black', fontWeight:"bold" }} onClick={() => navigate('/coach/plans')}>
          Programmes
        </Button>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
