import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Card, CardContent, Typography, Grid, IconButton, CircularProgress, Chip, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, ToggleButton, ToggleButtonGroup, TextField } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import CoachHeader from '../../../components/coach/CoachHeader';

const CoachPlanList = () => {
  const navigate = useNavigate();
  const [weekPlans, setWeekPlans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [planToDelete, setPlanToDelete] = useState(null);
  const [filter, setFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    const fetchWeekPlans = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/weekplans/clients`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch week plans');
        }
    
        const data = await response.json();
    
        const weekPlansWithUsers = await Promise.all(
          data.map(async (plan) => {
            const userId = typeof plan.user === 'string' ? plan.user : plan.user._id;
            const userResponse = await fetch(`${process.env.REACT_APP_BACKEND_URL}/api/users/clients/${userId}`, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('authToken')}`,
              },
            });
            if (userResponse.ok) {
              const userData = await userResponse.json();
              return { ...plan, user: userData };
            }
            return plan;
          })
        );
    
        setWeekPlans(weekPlansWithUsers);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchWeekPlans();
  }, []);

  const handleFilterChange = (event, newFilter) => {
    setFilter(newFilter);
  };

  const filteredPlans = weekPlans.filter(plan => {
    const matchesFilter = filter === 'all' || (filter === 'active' ? plan.user.active : !plan.user.active);
    const matchesSearch = `${plan.user.firstName} ${plan.user.lastName}`.toLowerCase().includes(searchTerm.toLowerCase());
    return matchesFilter && matchesSearch;
  });

  const handleViewPlan = (plan) => {
    const clientId = plan.user?._id || plan.user; // Récupère l'ID du client
    navigate(`/coach/plans/view/${clientId}/${plan._id}`);
  };
  
  const handleEditPlan = (plan) => {
    const clientId = plan.user?._id || plan.user; // Récupère l'ID du client
    navigate(`/coach/plans/edit/${clientId}/${plan._id}`);
  };
  

  const handleDeletePlanClick = (plan) => {
    setPlanToDelete(plan);
    setDeleteDialogOpen(true);
  };

  const handleDeletePlanConfirm = async () => {
    try {
      const clientId = planToDelete.user._id || planToDelete.user; // Supporte à la fois un objet utilisateur ou un ID direct
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL}/api/weekplans/clients/${clientId}/${planToDelete._id}`,
        {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('authToken')}`,
          },
        }
      );
  
      if (!response.ok) {
        throw new Error('Failed to delete week plan');
      }
  
      setWeekPlans(prevPlans => prevPlans.filter(plan => plan._id !== planToDelete._id));
      setDeleteDialogOpen(false);
      setPlanToDelete(null);
    } catch (error) {
      setError('Failed to delete plan');
    }
  };
  

  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
    setPlanToDelete(null);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return <Typography variant="h6" color="error">Erreur: {error}</Typography>;
  }

  return (
    <>
      <CoachHeader />

      <Box sx={{ px: { xs: 2, sm: 6 }, pt: 3, pb: 6, maxWidth: '880px', width: { xs: '-webkit-fill-available', md: '100%' }, margin: '0 auto' }}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
          <Typography variant="h3">Programmes</Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => navigate('/coach/plans/create')}
          >
            Ajouter
          </Button>
        </Stack>

        <Box sx={{ display: 'flex', alignItems: 'center', mb: 3 }}>
          <ToggleButtonGroup
            value={filter}
            exclusive
            onChange={handleFilterChange}
            sx={{ mr: 3 }}
          >
            <ToggleButton sx={{ padding: '15px' }} value="all">Tous</ToggleButton>
            <ToggleButton sx={{ padding: '15px' }} value="active">Actifs</ToggleButton>
            <ToggleButton sx={{ padding: '15px' }} value="inactive">Inactifs</ToggleButton>
          </ToggleButtonGroup>
          <TextField
            label="Rechercher..."
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
          />
        </Box>

        <Grid container spacing={3}>
          {filteredPlans.map((plan) => (
            <Grid item xs={12} sm={6} md={4} key={plan._id}>
              <Card elevation={0} sx={{ border: '1px solid #DDD', px: 1, pt: 1, pb: 0 }}>
                <CardContent>
                  <Typography variant="h4" sx={{ mb: 1 }}>
                    {plan.user ? `${plan.user.firstName} ${plan.user.lastName}` : 'Plan Inconnu'}
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 1, mb: 2 }}>Depuis le {new Date(plan.createdAt).toLocaleDateString()}</Typography>
                  <Chip label={plan.user && plan.user.active ? 'Actif' : 'Inactif'} color={plan.user.active ? 'primary' : 'default'} />
                  <Box sx={{ mt: 2, display: 'flex', justifyContent: 'flex-start' }}>
                    <IconButton onClick={() => handleViewPlan(plan)}>
                      <VisibilityIcon color="black" />
                    </IconButton>
                    <IconButton onClick={() => handleEditPlan(plan)}>
                      <EditIcon color="black" />
                    </IconButton>
                    <IconButton onClick={() => handleDeletePlanClick(plan)}>
                      <DeleteIcon color="black" />
                    </IconButton>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>

        <Dialog
          open={deleteDialogOpen}
          onClose={handleDeleteDialogClose}
        >
          <DialogTitle>Confirmer la suppression</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Êtes-vous sûr de vouloir supprimer le programme ? Cette action est irréversible.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDeleteDialogClose} color="primary">
              Annuler
            </Button>
            <Button onClick={handleDeletePlanConfirm} color="error">
              Supprimer
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

export default CoachPlanList;
